import React from 'react';
import { ReactComponent as CHAdeMO } from 'assets/images/svg/connector-icons/chademo.svg';
import { ReactComponent as AcType2 } from 'assets/images/svg/connector-icons/actype2.svg';
import { ReactComponent as CCS2 } from 'assets/images/svg/connector-icons/CCS2.svg';
import { ReactComponent as TeslaSuperCharge } from 'assets/images/svg/connector-icons/teslasupercharger.svg';
import { ReactComponent as TransactionsIcon } from 'assets/images/card/transactions.svg';
import { ReactComponent as IncreaseIcon } from 'assets/images/card/increase_icon.svg';
import { ReactComponent as DecreaseIcon } from 'assets/images/card/decrease_icon.svg';
import { ReactComponent as DriverIcon } from 'assets/images/card/drivers.svg';
import { ReactComponent as EnergyIcon } from 'assets/images/card/energy.svg';
import { ReactComponent as RevenueIcon } from 'assets/images/card/revenue.svg';
import { ReactComponent as StationsIcon } from 'assets/images/card/stations.svg';
import { ReactComponent as UnPaid } from 'assets/images/card/unPaid.svg';
import { ReactComponent as NewUsers } from 'assets/images/card/newUsers.svg';
import { ReactComponent as Duration } from 'assets/images/card/duration.svg';
import { ReactComponent as CCS } from 'assets/images/svg/connector-icons/CCS.svg';
import { ReactComponent as Schuko } from 'assets/images/svg/connector-icons/Schuko.svg';

export const getSocketSvg = (name: string, color: string) => {
  switch (name) {
    case 'CCS':
      return <CCS fill={color}  />;
    case 'CCS2':
      return <CCS2 fill={color} />;
    case 'CHAdeMO':
      return <CHAdeMO fill={color} stroke={color} />;
    case 'Tesla Supercharger':
      return <TeslaSuperCharge fill={color} stroke={color} />;
    case 'TransactionsIcon':
      return <TransactionsIcon fill={color} />;
    case 'IncreaseIcon':
      return <IncreaseIcon fill={color} />;
    case 'DecreaseIcon':
      return <DecreaseIcon fill={color} />
    case 'DriverIcon':
      return <DriverIcon fill={color} />;
    case 'EnergyIcon':
      return <EnergyIcon fill={color} />;
    case 'NewUsers':
      return <NewUsers fill={color} />;
    case 'Duration':
      return <Duration fill={color} />;
    case 'UnPaid':
      return <UnPaid fill={color} />;
    case 'RevenueIcon':
      return <RevenueIcon fill={color} />;
    case 'StationsIcon':
      return <StationsIcon fill={color} />;
    case 'Type 2':
    case 'AC Type-2':
      return <AcType2 fill={color} />;
    case 'Schuko':
      return <Schuko fill={color} />;
    default:
      return <CCS2 fill={color} />;
  }
};

export default getSocketSvg;
