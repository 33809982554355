/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unsafe-optional-chaining */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SlidingModal from 'Components/Common/SlidingModal';
import { MapContainer, TileLayer, Marker, useMap, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Leaflet, { LatLng, LatLngBounds, latLngBounds } from 'leaflet';
import {
  Button,
  Card,
  CardBody,
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Col,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import Swal from 'sweetalert2';
import * as Icons from 'react-feather';
import EditContext from 'Components/Contexts/EditContext';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import getSocketSvg from 'helpers/Socket';
import { ChargeStationType } from 'declerations/DefaultTypes';
import { ASSETS_URL, ASSET_BASE_URL } from 'helpers/config';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css'; // sass
import useRefMemo from 'Components/Hooks/useRefMemo';
import {
  deleteLocationPhoto,
  getLocationById,
  uploadLocationPhoto,
} from 'actions';
import { toast } from 'react-toastify';
import withReactContent from 'sweetalert2-react-content';
import Dropzone from 'react-dropzone';

interface LocationDetailProps {
  isOpen: boolean;
  toggleHandle: () => void;
}
const iconPerson = new Leaflet.Icon({
  iconUrl: `${ASSET_BASE_URL}pin.svg`,
  iconRetinaUrl: `${ASSET_BASE_URL}pin.svg`,
  iconSize: new Leaflet.Point(30, 60),
});
interface IChangeView {
  markers: ChargeStationType[];
}

const styles: any = {
  dropzone: {
    borderRadius: 4,
    marginBottom: '1.5rem',
    marginTop: '0.5rem',
  },
  carouselContainer: {
    position: 'relative',
    marginBottom: 16,
  },
  imageContainer: {
    height: 350,
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  image: {
    objectFit: 'contain',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  deleteIcon: {
    position: 'absolute',
    color: '#fff',
    top: 16,
    right: 16,
    zIndex: 10,
    cursor: 'pointer',
  },
};
const _fixedEmptyArray: unknown[] = [];

const LocationDetail = ({ isOpen, toggleHandle }: LocationDetailProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const editContext = useContext(EditContext);
  const selectedLocation = editContext.editLocation;
  const selectLocation = editContext.SetEditLocation;
  const context = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [bounds, setbounds] = useState<LatLngBounds>();
  const MySwal = withReactContent(Swal);
  const [deletingImage, setDeletingImage] = useState<any>();
  const [activeIndex, setActiveIndex] = useState(0);
  const deleting = useRef(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const images = useRefMemo(
    (prevImages = _fixedEmptyArray) => {
      if (uploading) return prevImages;
      return selectedLocation.images;
    },
    [uploading, selectedLocation]
  );

  const id = selectedLocation._id;

  const deleteImage = async () => {
    if (deleting.current) return;
    deleting.current = true;
    const res = await deleteLocationPhoto(id, {
      locImage: deletingImage,
    }).catch((x) => {
      deleting.current = false;
      toast.error(t('ChargeStationActions.failedToDeletePhoto'));
    });
    if (res && res.data.deleted) {
      const nextImages = selectedLocation?.images.filter(
        (img: any) => img !== deletingImage
      );
      selectLocation({ ...selectedLocation, images: nextImages });
      setActiveIndex((prevIndex) =>
        Math.min(prevIndex, nextImages.length === 0 ? 0 : nextImages.length - 1)
      );
      setDeletingImage(undefined);
    }
    deleting.current = false;
  };

  const resetDeletingImage = useCallback(() => {
    if (deleting.current) return;
    setDeletingImage(undefined);
  }, [setDeletingImage]);

  const [animating, setAnimating] = useState<boolean>(false);

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  useEffect(() => {
    if (deletingImage) {
      MySwal.fire({
        title: t('ChargeStationDetail.deletePhotoTitle'),
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: t('no'),
        confirmButtonText: t('yes'),
      }).then((res) => {
        if (res.isConfirmed) {
          deleteImage();
        } else {
          resetDeletingImage();
        }
      });
    }
  }, [deletingImage]);

  const next = useCallback(() => {
    if (animating) return;
    const nextIndex =
      activeIndex === selectedLocation?.images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }, [selectedLocation?.images, animating, activeIndex, setActiveIndex]);

  const previous = useCallback(() => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? selectedLocation?.images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }, [selectedLocation?.images, animating, activeIndex, setActiveIndex]);

  const goToIndex = useCallback(
    (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    },
    [animating, setActiveIndex]
  );
  const onDrop = useCallback(
    async ([newImage]) => {
      setUploading(true);
      const res = await uploadLocationPhoto(id, newImage, t);
      if (res.isSuccess) {
        toast.success(t('EditLocation.photoUploadedSuccessfully'));
        const nextImages = [...images, res?.data?.locImagePostObjUrl];
        selectLocation({ ...selectedLocation, images: nextImages });
      } else {
        toast.error(t('EditLocation.photoUploadFailed'));
      }
      setUploading(false);
    },
    [id, t, selectedLocation, images, selectLocation]
  );
  useEffect(() => {
    setLoading(true);
    context
      .LoadChargeStations({ locationId: selectedLocation?._id })
      .then(async () => {});
    return () => {
      setActiveIndex(0);
    };
  }, [selectedLocation]);

  useEffect(() => {
    if (context.chargeStations) {
      const locationArray = context.chargeStations?.map(
        (x: ChargeStationType) =>
          x?.location && [x?.location[1], x?.location[0]]
      );
      setbounds(Leaflet.latLngBounds([...locationArray]));
      setLoading(false);
    }
  }, [context.chargeStations]);

  const ChangeView = () => {
    const map = useMap();
    if (context.chargeStations.location) {
      map.setView({
        lng: context.chargeStations[0].location[0],
        lat: context.chargeStations[0].location[1],
      });
    }

    const markerBounds = latLngBounds([]);
    context.chargeStations.forEach((item: ChargeStationType) => {
      if (item.location && item.location.length > 1)
        markerBounds.extend([item.location[1], item.location[0]]);
    });
    map.fitBounds(markerBounds); // <===== Error: Bounds are not valid.
    return null;
  };

  const slides = useRefMemo(() => {
    return images.map((item: any) => (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={item}>
        <div style={styles.imageContainer}>
          <img
            src={`${ASSETS_URL}${item}`}
            className='img-fluid'
            alt={' '}
            style={styles.image}
          />
        </div>
      </CarouselItem>
    ));
  }, [images]);
  return (
    <SlidingModal
      toggleHandle={toggleHandle}
      title={selectedLocation.title}
      isOpen={isOpen}
      size='xl'
    >
      <h5>{t('Location.chargeStationsMap')}</h5>
      <Card>
        <CardBody>
          {!loading && (
            <MapContainer
              zoom={11}
              style={{ width: '100%', height: '350px' }}
              className='markercluster-map'
              center={[38.958552, 35.417003]}
              bounds={bounds}
            >
              {context.chargeStations && context.chargeStations.length > 0 && (
                <ChangeView />
              )}

              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://api.maptiler.com/maps/voyager/256/{z}/{x}/{y}.png?key=GZCHDTppR0lsIJy3ghnK'
              />
              <MarkerClusterGroup showCoverageOnHover={false}>
                {context.chargeStations &&
                  context.chargeStations.map(
                    (station: ChargeStationType, index: number) =>
                      station.location &&
                      station.location[0] &&
                      station.location[1] && (
                        <Marker
                          icon={iconPerson}
                          position={[station.location[1], station.location[0]]}
                          key={index.toString()}
                        >
                          <Popup>
                            <div className=''>
                              <div className=''>
                                <h4>{t('Charge Station')}</h4>
                                <div style={{ fontSize: '14px' }}>
                                  <span style={{ color: 'grey' }}>
                                    {station.name}
                                  </span>
                                </div>
                              </div>

                              <div>
                                <h4 style={{ paddingTop: '2px' }}>
                                  {t('Charge Point Id')}
                                </h4>
                                <div style={{ fontSize: '14px' }}>
                                  <span style={{ color: 'grey' }}>
                                    {station.chargePointId}
                                  </span>
                                </div>
                              </div>

                              <div className='my-1'>
                                <Button
                                  className='col-12 align-self-center'
                                  onClick={() => {
                                    history.push(
                                      `/cpo/charge-station/${station._id}`
                                    );
                                  }}
                                >
                                  {t('goDetail')}
                                </Button>
                              </div>
                            </div>
                          </Popup>
                        </Marker>
                      )
                  )}
              </MarkerClusterGroup>
            </MapContainer>
          )}
        </CardBody>
      </Card>
      <h5>{t('Location.photos')}</h5>
      <Card>
        <CardBody>
          <div className='mb-4'>
            <Dropzone
              onDrop={onDrop}
              multiple={false}
              accept='image/*'
              disabled={uploading}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  style={{ height: 250 }}
                >
                  <input {...getInputProps()} />
                  <p>{t('ChargeStationDetail.uploadPhoto')}</p>
                </div>
              )}
            </Dropzone>
          </div>

          {images?.length ? (
            <div style={styles.carouselContainer}>
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={images}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                  direction='prev'
                  directionText='Previous'
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction='next'
                  directionText='Next'
                  onClickHandler={next}
                />
              </Carousel>
              <Icons.Trash
                color='red'
                size={28}
                onClick={() => {
                  setDeletingImage(images[activeIndex]);
                }}
                style={styles.deleteIcon}
              />
            </div>
          ) : null}
        </CardBody>
      </Card>
      <Row className='mb-2'>
        <Col>
          <h5>{t('Location.chargeStationsList')}</h5>
        </Col>
        {/* <Col className='text-end'>
          <Button color='primary'>Reset Selected</Button>
        </Col> */}
      </Row>
      <DataTable
        // selectableRows
        noDataText={t('Location.noChargingStation')}
        loading={loading}
        columns={[
          {
            sortable: false,
            maxWidth: '32px',
            width: '32px',
            minWidth: '32px',
            cell: (row: ChargeStationType) => {
              return (
                <div>
                  <span
                    id={`chargeStation${row._id}`}
                    style={{ padding: 8, cursor: 'pointer' }}
                  >
                    <span
                      style={{
                        padding: 4,
                        display: 'inline-block',
                        backgroundColor: (() => {
                          if (!row.isOnline) {
                            return '#dd3333';
                          }
                          return '#22dd22';
                        })(),
                        borderRadius: '50%',
                      }}
                    />
                  </span>
                  <UncontrolledTooltip
                    placement='top'
                    target={`chargeStation${row._id}`}
                  >
                    {t(
                      `ChargeStations.${(() => {
                        if (!row.isOnline) {
                          return 'chargeStationOffline';
                        }
                        return 'chargeStationOnline';
                      })()}`
                    )}
                  </UncontrolledTooltip>
                </div>
              );
            },
          },
          {
            name: t('ChargeStations.CS Id'),
            selector: (row: ChargeStationType) => row.chargePointId,
            sortable: true,
          },
          {
            name: t('ChargeStations.name'),
            selector: (row: ChargeStationType) => row.name,
            sortable: true,
          },
          {
            name: t('ChargeStations.connectors'),
            cell: (props: ChargeStationType) => {
              const { connectors } = props;
              return connectors && connectors.length > 0 ? (
                <div>
                  {connectors.map((x, index) => (
                    <span
                      key={index.toString()}
                      className='me-1'
                      id={`connector${x._id}`}
                    >
                      {getSocketSvg(
                        x.socketType,
                        (() => {
                          if (x.status && x.status.status) {
                            switch (x.status.status) {
                              case 'Available':
                                return '#44aa44';
                              case 'Preparing':
                                return '#44aa44';
                              case 'Finishing':
                                return '#44aa44';
                              case 'Reserved':
                                return '#44aa44';
                              case 'SuspendedEvse':
                                return '#dd3333';
                              case 'SuspendedEV':
                                return '#dd3333';
                              case 'Unavailable':
                                return '#dd3333';
                              case 'Faulted':
                                return '#dd3333';
                              case 'Charging':
                                return '#ccbb44';
                              default:
                                return '#dd3333';
                            }
                          }
                          if (!x.isOnline) {
                            return '#dd3333';
                          }
                          if (x.transactionState === 'Active') {
                            return '#ccbb44';
                          }
                          return '#44aa44';
                        })()
                      )}

                      <UncontrolledTooltip
                        placement='top'
                        target={`connector${x._id}`}
                      >
                        {x.status && x.status.status
                          ? x.status.status
                          : t(
                              `ChargeStations.${(() => {
                                if (!x.isOnline) {
                                  return 'connectorOffline';
                                }
                                if (x.transactionState === 'Active') {
                                  return 'connectorCharging';
                                }
                                return 'connectorAvailable';
                              })()}`
                            )}
                      </UncontrolledTooltip>
                    </span>
                  ))}
                </div>
              ) : (
                <div />
              );
            },
          },
        ]}
        data={context.chargeStations || []}
      />
    </SlidingModal>
  );
};

export default LocationDetail;
