import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, Row, Col, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import SlidingModal from 'Components/Common/SlidingModal';
import i18n from 'i18next';
import DataContext from 'Components/Contexts/DataContext';
import {
  createPermission,
  getDomains,
  getRoles,
  getApplications,
  getLocations,
} from 'actions';
import { PermissionType } from 'declerations/DefaultTypes';

const formSchema = () =>
  Yup.object().shape({
    userId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    scopeId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    roleId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
  });
interface AddPermissionsProps {
  isOpen: boolean;
  toggleHandle: () => void;
}
interface LocationType {
  _id: string;
  domainIdPath: [];
  dayEnergyCost: number;
  nightEnergyCost: number;
  isBlocked: boolean;
  isDeleted: boolean;
  domainId: string;
  title: string;
  creatorId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  application: {
    _id: string;
    domainIdPath: [];
    isBlocked: boolean;
    isDeleted: boolean;
    domainId: string;
    creatorId: string;
    name: string;
    currency: string;
    appKey: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
}
interface ScopeType {
  label: string;
  value: string;
}
const AddPermissions = ({ isOpen, toggleHandle }: AddPermissionsProps) => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const [roles, setRoles] = useState<PermissionType[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectOptions, setSelectOptions] = useState<any>([]);
  const scopeTypes = [
    { label: i18n.t('AddPermission.Application'), value: 'Application' },
    { label: i18n.t('AddPermission.Domain'), value: 'Domain' },
    { label: i18n.t('AddPermission.Location'), value: 'Location' },
  ];
  const initialValues: {
    scopeId: string;
    userId: string;
    scopeType: any;
    roleId: string;
  } = {
    scopeId: '',
    userId: '',
    scopeType: null,
    roleId: '',
  };

  useEffect(() => {
    context.LoadEmployees().then((response: any) => {
      setLoading(false);
    });
  }, []);
  const getScopes = (scope: string) => {
    switch (scope) {
      case 'Domain':
        getDomains().then((response: any) => {
          setSelectOptions(response.data.domains);
        });
        // eslint-disable-next-line object-shorthand
        getRoles({ scope: scope }).then((response: any) => {
          setRoles(response?.data?.roles);
        });
        break;
      case 'Application':
        getApplications().then((response: any) => {
          setSelectOptions(response.data.applications);
        });
        // eslint-disable-next-line object-shorthand
        getRoles({ scope: scope }).then((response: any) => {
          setRoles(response?.data?.roles);
        });
        break;
      case 'Location':
        getLocations().then((response: any) => {
          setSelectOptions(response.data.locations);
        });
        // eslint-disable-next-line object-shorthand
        getRoles({ scope: scope }).then((response: any) => {
          setRoles(response?.data?.roles);
        });
        break;
      default:
        break;
    }
  };
  return (
    <SlidingModal
      toggleHandle={toggleHandle}
      isOpen={isOpen}
      title={t('AddPermission.AddRole')}
      size='sm'
    >
      {!loading ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => {
            createPermission(values)
              .then((response) => {
                toast.success(t('AddPermission.successfullyAdded'));
                return toggleHandle();
              })
              .catch((e) => {
                toast.error(t('AddPermission.couldntCreatePermission'));
              });
          }}
          validationSchema={formSchema}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            errors,
            setFieldTouched,
          }) => {
            useMemo(() => {
              Object.keys(errors).forEach((fieldName) => {
                setFieldTouched(fieldName);
              });
            }, [i18n.language]);
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <label htmlFor='userId'>{t('AddPermission.Users')}</label>
                    <Select
                      id='userId'
                      options={context.employees}
                      getOptionLabel={(x) => `${x.fname} ${x.lname}`}
                      getOptionValue={(x) => x._id}
                      placeholder={t('Select')}
                      onChange={(data: any) => {
                        setFieldValue('userId', data._id);
                      }}
                    />
                    <ErrorMessage
                      name='userId'
                      component='div'
                      className='text-danger text-sm'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label htmlFor='ScopeType'>
                      {t('AddPermission.ScopeType')}
                    </label>
                    <Select
                      id='ScopeType'
                      getOptionLabel={(x: any) => x.label}
                      getOptionValue={(x: any) => x.value}
                      placeholder={t('Select')}
                      onChange={(data: any) => {
                        setFieldValue('scopeId', '');
                        setFieldValue('scopeType', data.value);
                        setFieldValue('roleId', '');
                        getScopes(data.value);
                      }}
                      // value={values.scopeType}
                      options={scopeTypes}
                    />
                    <ErrorMessage
                      name='scopeType'
                      component='div'
                      className='text-danger text-sm'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label htmlFor='ScopeId'>
                      {t('AddPermission.ScopeId')}
                    </label>
                    <Select
                      id='ScopeId'
                      options={selectOptions}
                      getOptionLabel={(x) => x.name || x.title}
                      getOptionValue={(x) => x._id}
                      placeholder={t('Select')}
                      value={selectOptions.find(
                        (x: any) => x.id === values.scopeId
                      )}
                      onChange={(data) => {
                        setFieldValue('scopeId', data._id);
                      }}
                    />
                    <ErrorMessage
                      name='scopeId'
                      component='div'
                      className='text-danger text-sm'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label htmlFor='Roles'>{t('AddPermission.Roles')}</label>
                    <Select
                      id='roleId'
                      options={roles}
                      getOptionLabel={(x: any) => x?.title}
                      getOptionValue={(x: any) => x.roleId}
                      // disabled={initialValues.scopeType === ''}
                      placeholder={t('Select')}
                      value={
                        roles
                          ? roles.find((x: any) => x._id === values.roleId)
                          : null
                      }
                      onChange={(data: any) => {
                        setFieldValue('roleId', data._id);
                      }}
                    />
                    <ErrorMessage
                      name='roleId'
                      component='div'
                      className='text-danger text-sm'
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col className='text-end'>
                    <Button
                      color='secondary'
                      type='button'
                      disabled={isSubmitting}
                      onClick={toggleHandle}
                      className='me-1'
                    >
                      {t('AddEmployes.cancel')}
                    </Button>
                    <Button
                      color='primary'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      {t('AddEmployes.add')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </SlidingModal>
  );
};
export default AddPermissions;
