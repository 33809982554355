/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unsafe-optional-chaining */
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';
import {
  Col,
  Row,
  FormGroup,
  Badge,
  AccordionItem,
  AccordionBody,
  Accordion,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { Turkish } from 'flatpickr/dist/l10n/tr';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import Container from 'Components/Common/Container';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import EditContext from 'Components/Contexts/EditContext';
import DriverDetail from 'pages/CRM/Drivers/DriverDetail/DriverDetail';
import { useHistory } from 'react-router-dom';
import { getLabelCurrency } from 'helpers/Currencies';
import SearchInput from 'Components/Common/SearchInput';
import SelectBox from 'Components/Common/SelectBox';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import ExportBlock from 'laxyExcel/ExportComponents';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import numeral from 'numeral';
import { getReports } from 'actions';
import AutoRefresh from 'Components/Common/AutoRefresh';
import StatsCards from './StatsCards';
import {
  ReportEmspTypes,
  DriverType,
  LocationType,
  ChargeStationType,
} from '../../../declerations/DefaultTypes';
import TabSection from './SlidingModal/TabSection';
import 'moment/locale/tr';
import 'moment/locale/en-nz';
import i18n from '../../../i18n';

const Report = ({ ocppTagId }: { ocppTagId?: string | undefined }) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const [data, setData] = useState<ReportEmspTypes[] | undefined>();
  const [count, setCount] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(false);
  const [PerRows, setPerRows] = useState<number>(100);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const context = useContext(DataContext);
  const editContext = useContext(EditContext);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const applicationContext = useContext(ApplicationContext);
  const [filter, setFilter] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
    applicationId: string | undefined;
    locationId: string | undefined;
    chargePointId: string | undefined;
    state: string | undefined;
    user: string | undefined;
    ocppTagId: string | undefined;
    limit: number | undefined;
    offset: number | undefined;
  }>({
    applicationId: applicationContext.selectedApplication?._id || 'null',
    locationId: 'null',
    chargePointId: 'null',
    state: 'null',
    user: 'null',
    endDate: 'null',
    startDate: 'null',
    ocppTagId: 'null',
    limit: PerRows,
    offset: 0,
  });
  const [filtered, setFiltered] = useState('');
  const [selectedPayment, setSelectedPayment] = useState<any>();
  const [selectedRangeType, setSelectedRangeType] = useState('all');
  const [rangeDate, setRangeDate] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(),
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [filterOpened, setfilterOpened] = useState('1');
  const [isDriverDetailOpen, setisDriverDetailOpen] = useState(false);
  const refApplication = useRef<string>();
  const refLocation = useRef<string>();
  const refChargePoint = useRef<string>();
  const refStartDate = useRef<string>();
  const refOcppTagId = useRef<string>();
  const refState = useRef<string>();
  const refEndDate = useRef<string>();
  const refUser = useRef<string>();

  const loadDataTable = () => {
    if (filter.applicationId !== 'null') {
      if (
        refApplication.current === filter.applicationId &&
        refLocation.current === filter.locationId &&
        refChargePoint.current === filter.chargePointId
      ) {
        getReports(filter)
          .then((res) => {
            setData(res.data.report);
            setCount(res.data.count);
          })
          .then(() => {
            context.LoadDrivers();
          });
      } else {
        getReports(filter)
          .then((res) => {
            setData(res.data.report);
            setCount(res.data.count);
          })
          .then(() => {
            context.LoadLocations({ applicationId: filter.applicationId });
          })
          .then(() => {
            context.LoadChargeStations({
              applicationId: filter.applicationId,
            });
            setLoading(false);
          })
          .then(() => {
            context.LoadDrivers();
          })
          .then(() => {
            refChargePoint.current = filter.chargePointId;
            refLocation.current = filter.locationId;
            refApplication.current = filter.applicationId;
            refUser.current = filter.user;
          });
      }
    }
  };

  useEffect(() => {
    loadDataTable();
    console.log(count);
  }, [filter]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);
    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh, filter]);

  const search = (rowData: ReportEmspTypes[] | undefined) => {
    if (!rowData) {
      return [];
    }
    return rowData.filter(
      (row: ReportEmspTypes) =>
        (row.chargePointId &&
          `${row?.chargePointId?.toLowerCase()}`.indexOf(
            filtered.toLowerCase()
          ) > -1) ||
        (row.branchCode &&
          `${row?.branchCode?.toLowerCase()}`.indexOf(filtered.toLowerCase()) >
            -1) ||
        (row.tariff &&
          `${row?.tariff?.toLowerCase()}`.indexOf(filtered.toLowerCase()) >
            -1) ||
        (row.plate &&
          `${row?.plate?.toLowerCase()}`.indexOf(filtered.toLowerCase()) >
            -1) ||
        (row.location &&
          `${row?.location?.toLowerCase()}`.indexOf(filtered.toLowerCase()) >
            -1) ||
        (row?.userId && `${row?.userId}`.indexOf(filtered.toLowerCase()) > -1)
    );
  };
  const changeSelectedRangeType = (type: string) => {
    const baseDate = new Date();
    let startDate: Date | string = new Date();
    let endDate: Date | string = new Date(
      baseDate.getFullYear(),
      baseDate.getMonth(),
      baseDate.getDate() + 1
    );
    if (type === 'today') {
      startDate.setHours(0, 0, 0, 0);
      endDate = moment(rangeDate[1]).toDate();
      endDate.setHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'thisWeek') {
      startDate = new Date(
        baseDate.setDate(baseDate.getDate() - baseDate.getDay())
      );
      startDate.setHours(0, 0, 0, 0);
      endDate = moment().endOf('week').add(1, 'days').toDate();
      endDate.setHours(23, 59, 59, 999);
      const startDateDay = startDate.toLocaleDateString('en-US', {
        weekday: 'long',
      });

      if (startDateDay === 'Sunday') {
        startDate = moment(startDate).add(1, 'days').toDate();
        endDate = moment(endDate).add(-1, 'days').toDate();
      }
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'thisMonth') {
      startDate = new Date(baseDate.getFullYear(), baseDate.getMonth(), 1);
      endDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate() + 1
      );
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'thisYear') {
      startDate = new Date(baseDate.getFullYear(), 0, 1);
      endDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate() + 1
      );
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'customDate') {
      startDate = moment(rangeDate[0]).toDate();
      endDate = moment(rangeDate[1]).add(1, 'days').toDate();
      startDate.setHours(0, 0, 0, 0);
      endDate = moment(rangeDate[1]).toDate();
      endDate.setHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate });
    } else if (type === 'all') {
      setFilter({ ...filter, endDate: 'null', startDate: 'null' });
    }
    setSelectedRangeType(type);
  };

  useEffect(() => {
    if (selectedRangeType === 'customDate') {
      changeSelectedRangeType('customDate');
    }
  }, [rangeDate]);

  const changeFilter = (filterType: string, value: string) => {
    switch (filterType) {
      case 'applicationId':
        setFilter({
          ...filter,
          applicationId: value,
          locationId: 'null',
          chargePointId: 'null',
          ocppTagId: 'null',
          limit: PerRows,
          offset: 0,
        });
        setReset(!reset);

        break;
      case 'locationId':
        setFilter({
          ...filter,
          locationId: value,
          chargePointId: 'null',
          limit: PerRows,
          offset: 0,
        });
        setReset(!reset);
        break;
      case 'chargePointId':
        setFilter({
          ...filter,
          chargePointId: value,
          limit: PerRows,
          offset: 0,
        });
        setReset(!reset);
        break;
      case 'user':
        setFilter({ ...filter, user: value, limit: PerRows, offset: 0 });
        setReset(!reset);
        break;
      case 'state':
        setFilter({ ...filter, state: value, limit: PerRows, offset: 0 });
        setReset(!reset);
        break;
      case 'ocppTagId':
        setFilter({ ...filter, ocppTagId: value, limit: PerRows, offset: 0 });
        setReset(!reset);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (applicationContext.selectedApplication?._id)
      changeFilter(
        'applicationId',
        applicationContext.selectedApplication?._id
      );
  }, [applicationContext.selectedApplication]);

  const mappers = {
    branchCode: (row: any) => {
      if (row?.branchCode) {
        return `${row?.branchCode} `;
      }
      return `-`;
    },
    branchName: (row: any) => {
      if (row?.branchName) {
        return `${row?.branchName} `;
      }
      return `-`;
    },
    totalEnergy: (row: any) => {
      if (row?.powerConsumptionInKWh) {
        return `${row?.powerConsumptionInKWh.toFixed(2)} kWh`;
      }
      return `-`;
    },
    revenueSharePercentage: (row: any) => {
      if (row?.revenueSharePercentage) {
        return `${row?.revenueSharePercentage} %`;
      }
      return `-`;
    },
    location: (row: any) => {
      if (row?.location) {
        return `${row?.location} `;
      }
      return `-`;
    },
    tariff: (row: any) => {
      if (row?.tariff) {
        return `${row?.tariff} `;
      }
      return `-`;
    },
    tariffFee: (row: any) => {
      if (row?.tariffFee) {
        return `${row?.tariffFee} ${getLabelCurrency(row.currency, 'symbol')}`;
      }
      return `-`;
    },
    totalFee: (row: any) => {
      if (row?.totalFee) {
        return `${numeral(row.totalFee)
          .format('0.0,')
          .toString()} ${getLabelCurrency(row.currency, 'symbol')}`;
      }
      return `0.00 ${getLabelCurrency(row.currency, 'symbol')}`;
    },
    energyTariff: (row: any) => {
      if (row?.energyTariff) {
        return `${numeral(row?.energyTariff)
          .format('0.0,')
          .toString()} ${getLabelCurrency(row.currency, 'symbol')}`;
      }
      return `0.00 ${getLabelCurrency(row.currency, 'symbol')}`;
    },
    energyCost: (row: any) => {
      if (row?.energyCost) {
        return `${row?.energyCost} ${getLabelCurrency(row.currency, 'symbol')}`;
      }
      return `0.00 ${getLabelCurrency(row.currency, 'symbol')}`;
    },
    chargingDurationMin: (row: any) => {
      if (row?.chargingDurationInMins) {
        return `${row?.chargingDurationInMins.toFixed(2)} ${t('min')}`;
      }
      return `-`;
    },
    durationMin: (row: any) => {
      if (row.totalDuration) {
        return `${row.totalDuration.toFixed(2)} ${t('min')}`;
      }
      return `-`;
    },
    parkingTariff: (row: any) => {
      if (row?.parkingTariff) {
        return `${row?.parkingTariff} `;
      }
      return `-`;
    },
    parkingDurationMin: (row: any) => {
      if (row?.parkingDurationInMins) {
        return `${row?.parkingDurationInMins.toFixed(2)} ${t('min')}`;
      }
      return `-`;
    },
    parkingFee: (row: any) => {
      if (row?.parkingFeePerMin != null) {
        return `${row?.parkingFeePerMin.toFixed(2)} ${getLabelCurrency(
          row.currency,
          'symbol'
        )}`;
      }
      return `-`;
    },
    profit: (row: any) => {
      if (row?.profit) {
        return `${numeral(row?.profit)
          .format('0.0,')
          .toString()} ${getLabelCurrency(row.currency, 'symbol')}`;
      }
      return `0.00 ${getLabelCurrency(row.currency, 'symbol')}`;
    },
    plateNo: (row: any) => {
      if (row.plate) {
        return `${row.plate} `;
      }
      return `-`;
    },
    driver: (row: any) => {
      if (row.userId) {
        return `${row.userId} `;
      }
      return `-`;
    },
    dealerMargin: (row: any) => {
      if (row.dealerMargin) {
        return `${numeral(row.dealerMargin)
          .format('0.0,')
          .toString()} ${getLabelCurrency(row.currency, 'symbol')}`;
      }
      return `0.00 ${getLabelCurrency(row.currency, 'symbol')}`;
    },

    CPId: (row: any) => {
      if (row.chargePointId) {
        return `${row.chargePointId} `;
      }
      return `-`;
    },
    connectorID: (row: any) => {
      if (row?.socketId) {
        return `${row?.socketId} `;
      }
      return `-`;
    },
    startDate: (row: any) => {
      if (row.inProgress) {
        return '-';
      }
      if (i18n.language === 'en') {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        `${moment(row.startDate).format('lll').toString()}`;
      }
      return `${moment(row.startDate).locale('tr').format('lll').toString()}`;
    },
    endDate: (row: any) => {
      if (row.inProgress) {
        return '-';
      }
      if (i18n.language === 'en') {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        `${moment(row.endDate).format('lll').toString()}`;
      }
      return `${moment(row.endDate).locale('tr').format('lll').toString()}`;
    },
  };

  const headers = [
    'revenueSharePercentage',
    'location',
    'tariff',
    'tariffFee',
    'totalEnergy',
    'totalFee',
    'energyTariff',
    'energyCost',
    'chargingDurationMin',
    'durationMin',
    'parkingTariff',
    'parkingDurationMin',
    'parkingFee',
    'profit',
    'dealerMargin',
    'plateNo',
    'driver',
    'startDate',
    'endDate',
    'CPId',
    'connectorID',
  ].map((key: string) => ({
    key,
    value: `report.${key}`,
    mapper: mappers[key as keyof typeof mappers],
  }));
  const pagination = (e: number) => {
    setPageNumber(e);
    const control = count / PerRows;
    const flag = Math.ceil(control);
    if (e <= flag) {
      setFilter({
        ...filter,
        offset: PerRows && (e - 1) * PerRows,
        limit: PerRows,
      });
    } else if (e === flag) {
      setFilter({
        ...filter,
        offset: count,
        limit: PerRows,
      });
    }
  };
  const handlePerRowsChange = (e: number) => {
    setPerRows(e);
    setReset(!reset);
    setFilter({
      ...filter,
      offset:
        PerRows && pageNumber === 0 ? pageNumber * e : (pageNumber - 1) * e,
      limit: e,
    });
  };
  return (
    <Container
      title={ocppTagId ? '' : t('Report')}
      className={ocppTagId ? 'pt-4' : ''}
      rightSide={
        <AutoRefresh
          isActive={isActiveAutoRefresh}
          onPress={() => {
            setIsActiveAutoRefresh(!isActiveAutoRefresh);
          }}
        />
      }
    >
      {/* StatsCards */}
      {!ocppTagId && (
        <Row className='d-flex flex-wrap'>
          <Col xl={12} lg={12} sm={12} xs={12}>
            <StatsCards
              startDate={filter.startDate}
              endDate={filter.endDate}
              applicationId={filter.applicationId}
              locationId={filter.locationId}
              chargePointId={filter.chargePointId}
              user={filter.user}
              ocppTagId={filter.ocppTagId}
            />
          </Col>
        </Row>
      )}
      <Row className='mb-4'>
        <Col>
          <Accordion open={filterOpened} className='v2'>
            <AccordionItem>
              <AccordionBody accordionId='1'>
                <Row>
                  <Col xl={10} sm={12}>
                    <Row>
                      <Col xl={3} sm={12}>
                        <label
                          style={{
                            color: 'rgba(0,0,0,0.87)',
                            fontWeight: '600',
                            fontSize: '14px',
                          }}
                          htmlFor='range-picker'
                        >
                          &nbsp;
                          {t('')}
                        </label>
                        <SearchInput
                          isFilter
                          onFocus={() => setfilterOpened('1')}
                          onChange={(text) => {
                            setFiltered(text);
                          }}
                        />
                      </Col>
                      <Col xl={3} sm={12}>
                        <Row>
                          <Col>
                            <FormGroup className='m-0'>
                              <label
                                style={{
                                  fontWeight: '400',
                                  fontSize: '14px',
                                }}
                                className='fw-regular'
                                htmlFor='date-range'
                              >
                                {t('Transaction.chooseDateRange')}
                              </label>
                              <select
                                id='date-range'
                                className='form-control form-select mb-1'
                                value={selectedRangeType}
                                onChange={(e) =>
                                  changeSelectedRangeType(e.target.value)
                                }
                              >
                                <option style={{ color: 'black' }} value='all'>
                                  {t('Transaction.all')}
                                </option>
                                <option
                                  style={{ color: 'black' }}
                                  value='today'
                                >
                                  {t('Transaction.today')}
                                </option>
                                <option
                                  style={{ color: 'black' }}
                                  value='thisWeek'
                                >
                                  {t('Transaction.thisWeek')}
                                </option>
                                <option
                                  style={{ color: 'black' }}
                                  value='thisMonth'
                                >
                                  {t('Transaction.thisMonth')}
                                </option>
                                <option
                                  style={{ color: 'black' }}
                                  value='thisYear'
                                >
                                  {t('Transaction.thisYear')}
                                </option>
                                <option
                                  style={{ color: 'black' }}
                                  value='customDate'
                                >
                                  {t('Transaction.customDate')}
                                </option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      {selectedRangeType === 'customDate' && (
                        <Col xl={3} sm={12} xs={12}>
                          <Row>
                            <Col xl={12} sm={12} className='text-align'>
                              <FormGroup className='m-0'>
                                <label
                                  style={{
                                    fontWeight: '400',
                                    fontSize: '14px',
                                  }}
                                  htmlFor='range-picker'
                                >
                                  {t('Transaction.chooseDateRange')}
                                </label>
                                <Flatpickr
                                  value={rangeDate}
                                  id='range-picker'
                                  className='form-control'
                                  onChange={(date) => {
                                    if (date.length > 0) {
                                      setRangeDate(date);
                                    }
                                  }}
                                  options={{
                                    mode: 'range',
                                    maxDate: new Date(),
                                    locale:
                                      t('Transaction.locale') === 'Turkish'
                                        ? Turkish
                                        : undefined,
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      )}

                      <Col xl={3} sm={12} xs={12}>
                        <SelectBox
                          value={filter.locationId}
                          label={t('Transaction.location')}
                          onChange={(value) =>
                            changeFilter('locationId', value)
                          }
                        >
                          <option value='null'>{t('all')}</option>
                          {context.locations &&
                            context.locations.length > 0 &&
                            context.locations.map((item: LocationType) => (
                              <option key={item._id} value={item._id}>
                                {item.title}
                              </option>
                            ))}
                        </SelectBox>
                      </Col>
                      <Col xl={3} sm={12} xs={12}>
                        <SelectBox
                          value={filter.chargePointId}
                          label={t('Transaction.chargePoint')}
                          onChange={(value) =>
                            changeFilter('chargePointId', value)
                          }
                        >
                          <option value='null'>{t('all')}</option>
                          {context.chargeStations &&
                            context.chargeStations.length > 0 &&
                            context.chargeStations.map(
                              (item: ChargeStationType) => (
                                <option
                                  key={item.chargePointId}
                                  value={item.chargePointId}
                                >
                                  {item.name}
                                </option>
                              )
                            )}
                        </SelectBox>
                      </Col>
                      <Col xl={3} sm={12} xs={12}>
                        <SelectBox
                          value={filter.user}
                          label={t('Transaction.startedBy')}
                          onChange={(value) => changeFilter('user', value)}
                        >
                          <option value='null'>{t('all')}</option>
                          <option value='Customer'>
                            {t('Transaction.customer')}
                          </option>
                          <option value='CPO'>{t('Transaction.Cpo')}</option>
                        </SelectBox>
                      </Col>
                      <Col xl={3} sm={12}>
                        <SelectBox
                          value={filter.state}
                          label={t('ChargeStations.state')}
                          onChange={(value) => changeFilter('state', value)}
                        >
                          <option value='null'>{t('all')}</option>
                          <option value='Refunded'>
                            {t('Transaction.refund')}
                          </option>
                          <option value='Paid'>{t('Transaction.paid')}</option>
                          <option value='Unpaid'>
                            {t('Transaction.unpaid')}
                          </option>
                        </SelectBox>
                      </Col>
                      <Col xl={3} sm={12}>
                        <SelectBox
                          value={filter.ocppTagId}
                          label={t('Authorization')}
                          onChange={(value) => changeFilter('ocppTagId', value)}
                        >
                          <option value='null'>{t('all')}</option>
                          {context.ocppTags &&
                            context.ocppTags.length > 0 &&
                            context.ocppTags.map((item: any) => (
                              <option
                                key={item.chargePointId}
                                value={item.idTag}
                              >
                                {item.tagName || item.idTag}
                              </option>
                            ))}
                        </SelectBox>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={2} sm={12}>
                    <br />
                    <div
                      className='flex justify-content-end'
                      style={{ float: 'right', marginTop: 2 }}
                    >
                      <ExportBlock
                        data={data}
                        header={headers}
                        name={t('Report')}
                      />
                    </div>
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} sm={12} className='CustomTable'>
          <div className='mb-2' style={{ fontWeight: '700', color: 'red' }}>
            {t('allPriceIncludeVat')}
          </div>
          <DataTable
            loading={loading}
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={PerRows}
            onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
            onChangePage={(e) => pagination(e)}
            paginationResetDefaultPage={reset}
            paginationRowsPerPageOptions={[30, 50, 100, 500, 1000]}
            noDataText={t('thereAreNoReport')}
            // onRowClicked={(row, event) => {
            //   setSelectedPayment(row);
            //   editContext.SetEditTransaction(row);
            //   setIsOpen(true);
            // }}
            columns={[
              {
                name: <div>{t('Transaction.revenueSharePercentage')}</div>,
                grow: 1.5,
                // name: t('Transaction.revenueSharePercentage'),
                selector: (row: ReportEmspTypes) => row?.revenueSharePercentage,
                cell: (row: ReportEmspTypes) =>
                  row?.revenueSharePercentage != null
                    ? `${row.revenueSharePercentage} %`
                    : '-',
              },
              {
                name: <div>{t('Transaction.location')}</div>,

                selector: (row: ReportEmspTypes) => row?.location,
                cell: (row: ReportEmspTypes) =>
                  row?.location != null ? row.location : '-',
              },
              {
                name: <div>{t('Transaction.tariff')}</div>,

                // tarife adi
                selector: (row: ReportEmspTypes) => row?.tariff,
                cell: (row: ReportEmspTypes) =>
                  row?.tariff != null ? row.tariff : '-',
              },
              {
                // feeper kwh
                name: <div>{t('Transaction.tariffFee')}</div>,

                selector: (row: ReportEmspTypes) => row?.tariffFee,
                cell: (row: ReportEmspTypes) =>
                  row?.tariffFee != null
                    ? `${row?.tariffFee} ${getLabelCurrency(
                        row.currency,
                        'symbol'
                      )}`
                    : '-',
              },
              {
                // guc tuketimi
                name: <div>{t('Transaction.totalEnergy')}</div>,

                selector: (row: ReportEmspTypes) => row.powerConsumptionInKWh,
                cell: (row: ReportEmspTypes) =>
                  row.powerConsumptionInKWh
                    ? `${row.powerConsumptionInKWh} kWh`
                    : '-',
              },
              {
                // total fee
                grow: 1.5,
                name: <div>{t('Transaction.totalFee')}</div>,

                sortable: true,
                selector: (row: ReportEmspTypes) => row.totalFee,
                cell: (row: ReportEmspTypes) => (
                  <div className='text-primary'>
                    {row.totalFee
                      ? `${numeral(row.totalFee)
                          .format('0.0,')
                          .toString()} ${getLabelCurrency(
                          row.currency,
                          'symbol'
                        )}`
                      : `0 ${getLabelCurrency(row.currency, 'symbol')}`}
                  </div>
                ),
              },
              {
                // energytarifesi
                name: <div>{t('Transaction.energyTariff')}</div>,

                selector: (row: ReportEmspTypes) => row?.energyTariff,
                cell: (row: ReportEmspTypes) =>
                  row?.energyTariff != null
                    ? `${numeral(row?.energyTariff)
                        .format('0.0,')
                        .toString()} ${getLabelCurrency(
                        row.currency,
                        'symbol'
                      )}`
                    : '-',
              },
              {
                // enerji cost // maliyet
                name: <div>{t('Transaction.energyCost')}</div>,

                selector: (row: ReportEmspTypes) => row.energyCost,
                cell: (row: ReportEmspTypes) =>
                  row?.energyCost != null
                    ? `${row?.energyCost.toFixed(2)} ${getLabelCurrency(
                        row.currency,
                        'symbol'
                      )}`
                    : '-',
              },
              {
                // cp duration
                grow: 1.5,
                name: <div>{t('Transaction.chargingDurationMin')}</div>,

                selector: (row: ReportEmspTypes) => row.chargingDurationInMins,
                cell: (row: ReportEmspTypes) =>
                  row?.chargingDurationInMins != null
                    ? `${row.chargingDurationInMins.toFixed(2)} ${t('min')}`
                    : '-',
              },
              {
                // duration
                grow: 1.5,
                name: <div>{t('Transaction.durationMin')}</div>,

                selector: (row: ReportEmspTypes) => row.totalDuration,
                cell: (row: ReportEmspTypes) =>
                  row.totalDuration != null
                    ? `${row.totalDuration.toFixed(2)} ${t('min')}`
                    : '-',
              },
              {
                // parking tariff
                name: <div>{t('Transaction.parkingTariff')}</div>,

                selector: (row: ReportEmspTypes) => row?.parkingTariff,
                cell: (row: ReportEmspTypes) =>
                  row?.parkingTariff != null ? row.parkingTariff : '-',
              },
              {
                // parking dur
                grow: 1.5,
                name: <div>{t('Transaction.parkingDurationMin')}</div>,

                selector: (row: ReportEmspTypes) => row?.parkingDurationInMins,
                cell: (row: ReportEmspTypes) =>
                  row?.parkingDurationInMins
                    ? `${row?.parkingDurationInMins} ${t('min')}`
                    : '-',
              },
              {
                // parking fee
                grow: 1.5,
                name: <div>{t('Transaction.parkingFee')}</div>,
                cell: (row: ReportEmspTypes) =>
                  row?.parkingFeePerMin !== null
                    ? `${row?.parkingFeePerMin.toFixed(2)} ${getLabelCurrency(
                        row.currency,
                        'symbol'
                      )}`
                    : `0 ${getLabelCurrency(row.currency, 'symbol')}`,
              },
              {
                // profit
                name: t('Transaction.profit'),
                selector: (row: ReportEmspTypes) => row?.profit,
                cell: (row: ReportEmspTypes) => (
                  <div className='text-primary'>
                    {row?.profit
                      ? `${numeral(row?.profit)
                          .format('0.0,')
                          .toString()} ${getLabelCurrency(
                          row.currency,
                          'symbol'
                        )}`
                      : `0.00 ${getLabelCurrency(row.currency, 'symbol')}`}
                  </div>
                ),
              },
              {
                name: <div>{t('Transaction.dealerMargin')}</div>,
                cell: (row: ReportEmspTypes) => (
                  <div className='text-primary'>
                    {row.dealerMargin
                      ? `${numeral(row.dealerMargin)
                          .format('0.0,')
                          .toString()} ${getLabelCurrency(
                          row.currency,
                          'symbol'
                        )}`
                      : `0.00 ${getLabelCurrency(row.currency, 'symbol')}`}
                  </div>
                ),
              },
              {
                name: <div>{t('Transaction.plateNo')}</div>,
                selector: (row: any) => (row.plate ? row.plate : '-'),
              },
              {
                // userID
                name: <div>{t('Transaction.driver')}</div>,
                selector: (row: ReportEmspTypes) => row.userId,
                cell: (row: ReportEmspTypes) => (row.userId ? row.userId : '-'),
              },
              {
                name: <div>{t('Transaction.startTime')}</div>,
                selector: (row: ReportEmspTypes) => row.startDate,
                grow: 1.6,
                sortable: true,
                cell: (row: ReportEmspTypes) =>
                  i18n.language === 'en'
                    ? moment(row.startDate).format('lll').toString()
                    : moment(row.startDate)
                        .locale('tr')
                        .format('lll')
                        .toString(),
              },
              {
                name: <div>{t('Transaction.endTime')}</div>,
                selector: (row: ReportEmspTypes) => row.endDate,
                grow: 1.6,
                sortable: true,
                cell: (row: ReportEmspTypes) =>
                  row.inProgress
                    ? '-'
                    : i18n.language === 'en'
                    ? moment(row.endDate).format('lll').toString()
                    : moment(row.endDate).locale('tr').format('lll').toString(),
              },
              {
                grow: 1.9,
                name: <div>{t('Transaction.CP Id')}</div>,
                selector: (row: ReportEmspTypes) =>
                  row.chargePointId ? row.chargePointId : '-',
              },
              {
                name: <div>{t('Transaction.connectorID')}</div>,
                selector: (row: ReportEmspTypes) => row?.socketId,
                cell: (row: ReportEmspTypes) =>
                  row?.socketId ? row?.socketId : '-',
              },
            ]}
            data={search(data) || []}
          />
        </Col>
      </Row>
      {isOpen && (
        <TabSection
          isOpen={isOpen}
          toggleHandle={() => setIsOpen(false)}
          selectedPayment={selectedPayment}
        />
      )}
      <DriverDetail
        isOpen={isDriverDetailOpen}
        toggleHandle={() => {
          context.LoadTransactions(filter);
          setisDriverDetailOpen(false);
        }}
      />
    </Container>
  );
};

Report.defaultProps = {
  ocppTagId: undefined,
};
export default Report;
