/* eslint-disable radix */
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { renderField, renderFieldAddOn } from 'Components/Common/Fields';
import Flatpickr from 'react-flatpickr';
import * as Yup from 'yup';
import {
  Button,
  Row,
  Col,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { toast } from 'react-toastify';
import EditContext from 'Components/Contexts/EditContext';
import SlidingModal from 'Components/Common/SlidingModal';
import * as Icons from 'react-feather';
import DataContext from 'Components/Contexts/DataContext';
import { editOcppTag, createOcppTag, getDrivers } from 'actions';
// import { ChargingRatesType } from 'declerations/DefaultTypes';
import { getLabelCurrency } from 'helpers/Currencies';
// import { validateIBAN, isValidIBAN } from 'ibantools';
import Select from 'react-select';
import numeral from 'numeral';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import moment from 'moment';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { DriverType } from 'declerations/DefaultTypes';
import { RfidTypesEnum } from 'helpers/Rfid';
import i18n from '../../../../i18n';

const searchSubject$ = new Subject();

const formSchema = () =>
  Yup.object().shape({
    idTag: Yup.string()
      .min(1, 'Required')
      .matches(/^[a-zA-Z0-9-_]*$/, i18n.t('onlyEnglishLetter'))
      .required(i18n.t('Required')),
    applicationId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    consumerId: Yup.string().when('prepaidType', (prepaidType) => {
      if (
        prepaidType === 'prepaidForCustomer' ||
        prepaidType === 'postpaidForCustomer'
      ) {
        return Yup.string().min(1, 'Required').required(i18n.t('Required'));
      }
      return Yup.string();
    }),
    maxActiveTransactions: Yup.number()
      .typeError(i18n.t('AddOcppTags.valueMustBeNumber'))
      .integer(i18n.t('AddOcppTags.valueMustBeInteger'))
      .min(1, i18n.t('AddOcppTags.valueMustBeBetwen500-0'))
      .max(500, i18n.t('AddOcppTags.valueMustBeBetwen500-0'))
      .required(i18n.t('Required')),
    balance: Yup.number().when('prepaidType', (prepaidType) => {
      if (prepaidType !== 'General' && prepaidType !== 'postpaidForCustomer') {
        return Yup.number()
          .min(1, 'please enter value greater than 1')
          .required(i18n.t('Required'));
      }
      return Yup.string();
    }),
    tagName: Yup.string().min(1, 'Required').required(i18n.t('Required')),
  });
const formSchemaEditing = () =>
  Yup.object().shape({
    idTag: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    applicationId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    maxActiveTransactions: Yup.number()
      .typeError(i18n.t('AddOcppTags.valueMustBeNumber'))
      .integer(i18n.t('AddOcppTags.valueMustBeInteger'))
      .min(1, i18n.t('AddOcppTags.valueMustBeBetwen500-0'))
      .max(500, i18n.t('AddOcppTags.valueMustBeBetwen500-0'))
      .required(i18n.t('Required')),
    tagName: Yup.string().min(1, 'Required').required(i18n.t('Required')),
  });
interface AddCpoProps {
  isOpen: boolean;
  isEditing: boolean;
  toggleHandle: () => void;
}

interface InitialValuesType {
  idTag: string;
  applicationId: string;
  maxActiveTransactions: string;
  expiryDateTime: string;
  tagName: string;
  balance: string;
  prepaid: boolean;
  consumerId: string;
  consumer: any;
  credit: string;
  debit: string;
  prepaidType: string;
}
const AddAdminrfids = ({ isOpen, toggleHandle, isEditing }: AddCpoProps) => {
  const context = useContext(DataContext);
  const editContext = useContext(EditContext);
  const { selectedApplication } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState('Credit');
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [textQuery, setTextQuery] = useState('');
  const [drivers, setDrivers] = useState([]);

  const [initialBalance, setInitialBalance] = useState('');
  const [initialValues, setInitialValues] = useState<InitialValuesType>({
    idTag: '',
    applicationId: '',
    maxActiveTransactions: '',
    expiryDateTime: '',
    tagName: '',
    balance: '',
    prepaid: false,
    consumerId: '',
    consumer: null,
    credit: '',
    debit: '',
    prepaidType: 'General',
  });

  const loadDrivers = async (query: string) => {
    getDrivers({ defaultCard: 1, keyword: query })
      .then((response) => {
        setDrivers(response.data.consumers);
      })
      .catch((e) => {
        // console.log('ERROR', e);
      })
      .finally(() => {
        setOptionsLoading(false);
      });
  };

  const { t } = useTranslation();
  useEffect(() => {
    context
      .LoadDomains()
      .then(() => context.LoadApplications())
      // .then(() => context.LoadDrivers({ defaultCard: 1 }))
      .then(() => setLoading(false));

    const searchSubscription = searchSubject$
      .asObservable()
      .pipe(distinctUntilChanged(), debounceTime(1500))
      .subscribe((query: any) => {
        if (query.length < 3) {
          setOptionsLoading(false);
          return;
        }
        loadDrivers(query);
      });
    return () => {
      searchSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (isEditing) {
      const {
        idTag = '',
        application: { _id: applicationId = '' },
        maxActiveTransactions = '',
        expiryDateTime = '',
        tagName = '',
        balance = '',
        isPrepaid = '',
        credit = '',
        prepaid = false,
        debit = '',
        consumer = null,
        consumerId = '',
      } = isEditing ? editContext.editOcppTag : { application: {} };
      setInitialBalance(balance);
      setInitialValues({
        idTag,
        applicationId,
        maxActiveTransactions,
        expiryDateTime,

        tagName,
        balance: isEditing ? balance : '',
        prepaid,
        consumerId,
        consumer,
        credit,
        debit,
        prepaidType:
          consumer != null &&
          // eslint-disable-next-line yoda, eqeqeq, no-self-compare
          editContext?.editOcppTag?.keyType === RfidTypesEnum.RFID_POSTPAID
            ? 'postpaidForCustomer'
            : // eslint-disable-next-line no-self-compare
            editContext?.editOcppTag?.keyType === RfidTypesEnum.RFID_PREPAID
            ? 'prepaidForCustomer'
            : isPrepaid
            ? 'Prepaid'
            : 'General',
      });
    } else {
      setInitialBalance('');

      setInitialValues({
        idTag: '',
        applicationId: selectedApplication?._id || '',
        maxActiveTransactions: '',
        expiryDateTime: '',
        tagName: '',
        balance: '',
        prepaid: false,
        consumerId: '',
        consumer: '',
        credit: '',
        debit: '',
        prepaidType: 'General',
      });
    }
  }, [isEditing, selectedApplication]);

  useEffect(() => {
    if (textQuery.length > 2) {
      setOptionsLoading(true);
      searchSubject$.next(textQuery);
    } else {
      searchSubject$.next('');
      setDrivers([]);
    }
  }, [textQuery]);

  function flatpckrPlaceholder(expiryDateTime: string) {
    if (isEditing) {
      const newDate = new Date(expiryDateTime);
      newDate.setHours(newDate.getHours() + 3);
      return moment(newDate).format('l');
    }
    return t('AddOcppTags.pleaseSelectADate');
  }
  return (
    <SlidingModal
      toggleHandle={toggleHandle}
      isOpen={isOpen}
      title={
        isEditing === false
          ? t('AddChargeKeys.AddChargeKey')
          : t('AddChargeKeys.EditChargeKey')
      }
      size='lg'
    >
      {!loading && isOpen ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => {
            const { prepaidType } = values;
            if (isEditing) {
              //   const { id } = this.state;
              const { _id: id } = editContext.editOcppTag;

              if (prepaidType === 'prepaidForCustomer') {
                const newValues = { ...values };
                newValues.debit = newValues.debit !== '' ? newValues.debit : '';
                newValues.credit =
                  newValues.credit !== '' ? newValues.credit : '';

                const {
                  prepaid,
                  applicationId,
                  balance,
                  // eslint-disable-next-line @typescript-eslint/no-shadow
                  prepaidType,
                  ...editValues
                } = newValues;

                return editOcppTag(id, { ...editValues })
                  .then(() => {
                    toast.success(t('AddOcppTags.ocppTagEditedSuccessfully'));

                    return toggleHandle();
                  })
                  .catch(() =>
                    toast.error(t('AddOcppTags.unabletoEditOcppTag'))
                  );
              }
              if (prepaidType === 'Prepaid') {
                // eslint-disable-next-line no-param-reassign
                values.prepaid = true;
                const {
                  applicationId,
                  prepaidType: cus,
                  consumer,
                  consumerId,
                  ...newValues
                } = values;

                return editOcppTag(id, { ...newValues })
                  .then((response) => {
                    toast.success(t('AddOcppTags.ocppTagEditedSuccessfully'));
                    return toggleHandle();
                  })
                  .catch(() =>
                    toast.error(t('AddOcppTags.unabletoEditOcppTag'))
                  );
                // eslint-disable-next-line no-else-return
              } else {
                const {
                  balance,
                  prepaid,
                  consumer,
                  applicationId,
                  prepaidType: cus,
                  credit,
                  debit,
                  consumerId,
                  ...newValues
                } = values;
                return editOcppTag(id, { ...newValues })
                  .then((response) => {
                    toast.success(t('AddOcppTags.ocppTagEditedSuccessfully'));
                    return toggleHandle();
                  })
                  .catch(() =>
                    toast.error(t('AddOcppTags.unabletoEditOcppTag'))
                  );
              }
            }
            setInitialBalance('');

            setInitialValues({
              idTag: '',
              applicationId: selectedApplication?._id || '',
              maxActiveTransactions: '',
              expiryDateTime: '',
              tagName: '',
              balance: '',
              prepaid: false,
              consumerId: '',
              consumer: '',
              credit: '',
              debit: '',
              prepaidType: 'General',
            });
            if (prepaidType === 'prepaidForCustomer') {
              const keyType = RfidTypesEnum.RFID_PREPAID;
              // eslint-disable-next-line no-param-reassign
              values.prepaid = true;
              const { prepaidType: cus, credit, debit, ...newValues } = values;
              return createOcppTag({ keyType, ...newValues })
                .then((response) => {
                  toast.success(t('AddOcppTags.ocppTagcreatedSuccessfully'));
                  return toggleHandle();
                })
                .catch(() =>
                  toast.error(t('AddOcppTags.unabletoCreateOcppTag'))
                );
            }
            if (prepaidType === 'Prepaid') {
              // eslint-disable-next-line no-param-reassign
              values.prepaid = true;
              const keyType = RfidTypesEnum.RFID_PREPAID;
              const {
                prepaidType: cus,
                credit,
                consumer,
                consumerId,
                debit,
                ...newValues
              } = values;
              return createOcppTag({ keyType, ...newValues })
                .then((response) => {
                  toast.success(t('AddOcppTags.ocppTagcreatedSuccessfully'));
                  return toggleHandle();
                })
                .catch(() =>
                  toast.error(t('AddOcppTags.unabletoCreateOcppTag'))
                );
              // eslint-disable-next-line no-else-return
            }
            if (prepaidType === 'postpaidForCustomer') {
              const keyType = RfidTypesEnum.RFID_POSTPAID;
              // eslint-disable-next-line no-param-reassign
              values.prepaid = false;
              const {
                prepaidType: cus,
                credit,
                debit,
                balance,
                ...newValues
              } = values;
              return createOcppTag({ keyType, ...newValues })
                .then((response) => {
                  toast.success(t('AddOcppTags.ocppTagcreatedSuccessfully'));
                  return toggleHandle();
                })
                .catch(() =>
                  toast.error(t('AddOcppTags.unabletoCreateOcppTag'))
                );
              // eslint-disable-next-line no-else-return
            } else {
              const keyType = RfidTypesEnum.RFID_FREE;
              const {
                balance,
                prepaid,
                consumer,
                prepaidType: cus,
                consumerId,
                credit,
                debit,
                ...newValues
              } = values;
              return createOcppTag({ keyType, ...newValues })
                .then(() => {
                  toast.success(t('AddOcppTags.ocppTagcreatedSuccessfully'));
                  return toggleHandle();
                })
                .catch(() =>
                  toast.error(t('AddOcppTags.unabletoCreateOcppTag'))
                );
            }
          }}
          validationSchema={isEditing ? formSchemaEditing : formSchema}
        >
          {({
            values,
            touched,
            errors,
            setFieldTouched,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            useMemo(() => {
              Object.keys(errors).forEach((fieldName) => {
                setFieldTouched(fieldName);
              });
            }, [i18n.language]);
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    {!isEditing && (
                      <Nav className='justify-content-center' tabs>
                        <NavItem style={{ cursor: 'pointer' }}>
                          <NavLink
                            active={values.prepaidType === 'General'}
                            onClick={() => {
                              if (isEditing) {
                                return;
                              }
                              setFieldValue('prepaidType', 'General');
                            }}
                          >
                            {t('AddOcppTags.general')}
                          </NavLink>
                        </NavItem>
                        <NavItem style={{ cursor: 'pointer' }}>
                          <NavLink
                            active={values.prepaidType === 'Prepaid'}
                            onClick={() => {
                              if (isEditing) {
                                return;
                              }
                              setFieldValue('prepaidType', 'Prepaid');
                            }}
                          >
                            {t('AddOcppTags.prepaid')}
                          </NavLink>
                        </NavItem>
                        <NavItem style={{ cursor: 'pointer' }}>
                          <NavLink
                            active={values.prepaidType === 'prepaidForCustomer'}
                            onClick={() => {
                              if (isEditing) {
                                return;
                              }
                              setFieldValue(
                                'prepaidType',
                                'prepaidForCustomer'
                              );
                            }}
                          >
                            {t('AddOcppTags.pfc')}
                          </NavLink>
                        </NavItem>
                        <NavItem style={{ cursor: 'pointer' }}>
                          <NavLink
                            active={
                              values.prepaidType === 'postpaidForCustomer'
                            }
                            onClick={() => {
                              if (isEditing) {
                                return;
                              }
                              setFieldValue(
                                'prepaidType',
                                'postpaidForCustomer'
                              );
                            }}
                          >
                            {t('AddOcppTags.postpaidForCustomer')}
                          </NavLink>
                        </NavItem>
                      </Nav>
                    )}
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col>
                    <Field
                      // eslint-disable-next-line no-unneeded-ternary
                      disabled={isEditing ? true : false}
                      component={renderField}
                      name='idTag'
                      label={t('AddOcppTags.idTag')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.idTag,
                        error: errors.idTag,
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderField}
                      name='tagName'
                      label={t('AddOcppTags.tagName')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.tagName,
                        error: errors.tagName,
                      }}
                    />
                  </Col>
                  {/* <Col>
                    <label htmlFor='application'>
                      {t('AddOcppTags.application')}
                    </label>
                    <Select
                      id='application'
                      isDisabled={!!isEditing}
                      options={context.applications}
                      onBlur={() => setFieldTouched('applicationId')}
                      getOptionLabel={(x) => x.name}
                      getOptionValue={(x) => x._id}
                      placeholder={t('Select')}
                      value={context.applications.find(
                        (x: ApplicationsType) => x._id === values.applicationId
                      )}
                      onChange={(data) => {
                        setFieldValue('applicationId', data._id);
                      }}
                    />
                    <ErrorMessage
                      name='applicationId'
                      component='div'
                      className='field-error text-danger text-sm'
                    />
                  </Col> */}
                </Row>
                <Row>
                  <Col>
                    <label htmlFor='expiryDateTime'>
                      {t('AddOcppTags.expiryDateTime')}
                    </label>
                    <Flatpickr
                      id='expiryDateTime'
                      className='form-control'
                      placeholder={flatpckrPlaceholder(values.expiryDateTime)}
                      value={values.expiryDateTime}
                      options={{
                        locale: t('flatpickrLocale'),
                        minDate: 'today',
                        maxDate: '2041-12-31',
                        defaultDate: '2040-12-31',
                        dateFormat: t('flatpickrDateFormat'),
                      }}
                      onChange={(dob: any) => {
                        setFieldValue('expiryDateTime', dob[0].toISOString());
                      }}
                    />
                    <ErrorMessage
                      name='applicationId'
                      component='div'
                      className='field-error text-danger text-sm'
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderField}
                      name='maxActiveTransactions'
                      label={t('AddOcppTags.maxActiveTransactions')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.maxActiveTransactions,
                        error: errors.maxActiveTransactions,
                      }}
                    />
                  </Col>
                </Row>

                <TabContent className='py-50' activeTab={values.prepaidType}>
                  <TabPane tabId='Prepaid'>
                    {values.prepaidType === 'Prepaid' && (
                      <Row>
                        <Col>
                          {isEditing ? (
                            <Field
                              // eslint-disable-next-line no-unneeded-ternary
                              disabled
                              component={renderFieldAddOn}
                              value={numeral(values.balance)
                                .format('0,0.0')
                                .toString()}
                              name='balance'
                              addOnText={
                                values.applicationId !== ''
                                  ? getLabelCurrency(
                                      (
                                        context.applications.find(
                                          (x: any) =>
                                            x._id === values.applicationId
                                        ) || {}
                                      ).currency,
                                      'symbol'
                                    )
                                  : ''
                              }
                              label={
                                t('AddOcppTags.balance') +
                                  values.applicationId !==
                                ''
                                  ? `${t(
                                      'AddOcppTags.balance'
                                    )}(${getLabelCurrency(
                                      (
                                        context.applications.find(
                                          (x: any) =>
                                            x._id === values.applicationId
                                        ) || {}
                                      ).currency,
                                      'symbol'
                                    )})`
                                  : ''
                              }
                              className={`form-control `}
                              meta={{
                                touched: touched.balance,
                                error: errors.balance && t(errors.balance),
                              }}
                            />
                          ) : (
                            <Field
                              // eslint-disable-next-line no-unneeded-ternary
                              component={renderFieldAddOn}
                              name='balance'
                              addOnText={
                                values.applicationId !== ''
                                  ? getLabelCurrency(
                                      (
                                        context.applications.find(
                                          (x: any) =>
                                            x._id === values.applicationId
                                        ) || {}
                                      ).currency,
                                      'symbol'
                                    )
                                  : ''
                              }
                              label={
                                t('AddOcppTags.balance') +
                                  values.applicationId !==
                                ''
                                  ? `${t(
                                      'AddOcppTags.balance'
                                    )}(${getLabelCurrency(
                                      (
                                        context.applications.find(
                                          (x: any) =>
                                            x._id === values.applicationId
                                        ) || {}
                                      ).currency,
                                      'symbol'
                                    )})`
                                  : ''
                              }
                              className={`form-control `}
                              type='number'
                              meta={{
                                touched: touched.balance,
                                error: errors.balance && t(errors.balance),
                              }}
                            />
                          )}
                        </Col>
                        {isEditing && (
                          <>
                            <Col>
                              <label htmlFor='method'>
                                {t('AddOcppTags.method')}
                              </label>
                              <select
                                id='method'
                                className='form-control'
                                value={check}
                                onChange={(e) => setCheck(e.target.value)}
                              >
                                <option
                                  style={{ color: 'black' }}
                                  value='Credit'
                                >
                                  {t('AddOcppTags.credits')}
                                </option>
                                <option
                                  style={{ color: 'black' }}
                                  value='Debit'
                                >
                                  {t('AddOcppTags.debits')}
                                </option>
                              </select>
                            </Col>
                            {check === 'Credit' ? (
                              <Col>
                                <Field
                                  component={renderFieldAddOn}
                                  name='credit'
                                  label={t('AddOcppTags.credit')}
                                  className='form-control'
                                  addOnText={
                                    values.applicationId !== ''
                                      ? getLabelCurrency(
                                          (
                                            context.applications.find(
                                              (x: any) =>
                                                x._id === values.applicationId
                                            ) || {}
                                          ).currency,
                                          'symbol'
                                        )
                                      : ''
                                  }
                                  type='number'
                                  onChange={(e: any) => {
                                    setFieldValue('credit', e.target.value);
                                    if (
                                      e.target.value !== '' ||
                                      e.target.value !== 0
                                    ) {
                                      setFieldValue(
                                        'balance',
                                        parseInt(initialBalance) +
                                          parseInt(
                                            e.target.value === ''
                                              ? 0
                                              : e.target.value
                                          )
                                      );
                                    } else {
                                      setFieldValue('balance', initialBalance);
                                    }
                                  }}
                                  meta={{
                                    touched: touched.credit,
                                    error: errors.credit,
                                  }}
                                />
                              </Col>
                            ) : (
                              ''
                            )}
                            {check === 'Debit' ? (
                              <Col>
                                <Field
                                  component={renderFieldAddOn}
                                  name='debit'
                                  label={t('AddOcppTags.debit')}
                                  className='form-control'
                                  type='number'
                                  addOnText={
                                    values.applicationId !== ''
                                      ? getLabelCurrency(
                                          (
                                            context.applications.find(
                                              (x: any) =>
                                                x._id === values.applicationId
                                            ) || {}
                                          ).currency,
                                          'symbol'
                                        )
                                      : ''
                                  }
                                  onChange={(e: any) => {
                                    setFieldValue('debit', e.target.value);
                                    if (
                                      parseInt(e.target.value) >
                                      parseInt(initialBalance)
                                    ) {
                                      setFieldValue('balance', 0);
                                    } else if (
                                      e.target.value !== '' ||
                                      e.target.value !== 0
                                    ) {
                                      setFieldValue(
                                        'balance',
                                        (
                                          parseInt(initialBalance) -
                                          parseInt(
                                            e.target.value === ''
                                              ? 0
                                              : e.target.value
                                          )
                                        ).toString()
                                      );
                                    } else {
                                      setFieldValue('balance', initialBalance);
                                    }
                                  }}
                                  meta={{
                                    touched,
                                    error: errors.debit,
                                  }}
                                />
                              </Col>
                            ) : (
                              ''
                            )}
                          </>
                        )}
                      </Row>
                    )}
                  </TabPane>
                  <TabPane tabId='prepaidForCustomer'>
                    {values.prepaidType === 'prepaidForCustomer' && (
                      <Row>
                        <Col>
                          <Field
                            disabled={!!isEditing}
                            component={renderFieldAddOn}
                            name='balance'
                            addOnText={
                              values.applicationId !== ''
                                ? getLabelCurrency(
                                    (
                                      context.applications.find(
                                        (x: any) =>
                                          x._id === values.applicationId
                                      ) || {}
                                    ).currency,
                                    'symbol'
                                  )
                                : ''
                            }
                            label={
                              t('AddOcppTags.balance') +
                                values.applicationId !==
                              ''
                                ? `${t(
                                    'AddOcppTags.balance'
                                  )}(${getLabelCurrency(
                                    (
                                      context.applications.find(
                                        (x: any) =>
                                          x._id === values.applicationId
                                      ) || {}
                                    ).currency,
                                    'symbol'
                                  )})`
                                : ''
                            }
                            className='form-control'
                            type='number'
                            meta={{
                              touched: touched.balance,
                              error: errors.balance && t(errors.balance),
                            }}
                          />
                        </Col>
                        {isEditing && (
                          <>
                            <Col>
                              <label htmlFor='method'>
                                {t('AddOcppTags.method')}
                              </label>
                              <select
                                id='method'
                                className='form-control'
                                value={check}
                                onChange={(e) => setCheck(e.target.value)}
                              >
                                <option
                                  style={{ color: 'black' }}
                                  value='Credit'
                                >
                                  {t('AddOcppTags.credits')}
                                </option>
                                <option
                                  style={{ color: 'black' }}
                                  value='Debit'
                                >
                                  {t('AddOcppTags.debits')}
                                </option>
                              </select>
                            </Col>
                            {check === 'Credit' ? (
                              <Col className='col-md-2'>
                                <Field
                                  component={renderFieldAddOn}
                                  name='credit'
                                  label={t('AddOcppTags.credit')}
                                  className='form-control'
                                  addOnText={
                                    values.applicationId !== ''
                                      ? getLabelCurrency(
                                          (
                                            context.applications.find(
                                              (x: any) =>
                                                x._id === values.applicationId
                                            ) || {}
                                          ).currency,
                                          'symbol'
                                        )
                                      : ''
                                  }
                                  type='number'
                                  onChange={(e: any) => {
                                    setFieldValue('credit', e.target.value);
                                    if (
                                      e.target.value !== '' ||
                                      e.target.value !== 0
                                    ) {
                                      setFieldValue(
                                        'balance',
                                        (
                                          parseInt(initialBalance) +
                                          parseInt(
                                            e.target.value === ''
                                              ? 0
                                              : e.target.value
                                          )
                                        ).toString()
                                      );
                                    } else {
                                      setFieldValue('balance', initialBalance);
                                    }
                                  }}
                                  meta={{
                                    touched,
                                    error: errors.credit,
                                  }}
                                />
                              </Col>
                            ) : (
                              ''
                            )}
                            {check === 'Debit' ? (
                              <Col className='col-md-2'>
                                <Field
                                  component={renderFieldAddOn}
                                  name='debit'
                                  label={t('AddOcppTags.debit')}
                                  className='form-control'
                                  addOnText={
                                    values.applicationId !== ''
                                      ? getLabelCurrency(
                                          (
                                            context.applications.find(
                                              (x: any) =>
                                                x._id === values.applicationId
                                            ) || {}
                                          ).currency,
                                          'symbol'
                                        )
                                      : ''
                                  }
                                  type='number'
                                  onChange={(e: any) => {
                                    setFieldValue('debit', e.target.value);
                                    if (
                                      parseInt(e.target.value) >
                                      parseInt(initialBalance)
                                    ) {
                                      setFieldValue('balance', 0);
                                    } else if (
                                      e.target.value !== '' ||
                                      e.target.value !== 0
                                    ) {
                                      setFieldValue(
                                        'balance',
                                        (
                                          parseInt(initialBalance) -
                                          parseInt(
                                            e.target.value === ''
                                              ? 0
                                              : e.target.value
                                          )
                                        ).toString()
                                      );
                                    } else {
                                      setFieldValue('balance', initialBalance);
                                    }
                                  }}
                                  meta={{
                                    touched: touched.debit,
                                    error: errors.debit,
                                  }}
                                />
                              </Col>
                            ) : (
                              ''
                            )}
                          </>
                        )}
                        <Col>
                          <label htmlFor='customerId'>
                            {t('AddOcppTags.customerId')}
                          </label>
                          <Select
                            id='customerId'
                            defaultValue={values?.consumer}
                            options={optionsLoading ? [] : drivers}
                            noOptionsMessage={() =>
                              optionsLoading ? (
                                <Spinner color='primary' className='m-auto' />
                              ) : textQuery.length < 3 ? (
                                t('ChargeStations.search')
                              ) : (
                                t('ChargeStations.noOptions')
                              )
                            }
                            onBlur={() => setFieldTouched('consumerId')}
                            onInputChange={(e: any) => {
                              setTextQuery(e);
                            }}
                            getOptionLabel={(x: any) => `${x.fname} ${x.lname}`}
                            getOptionValue={(x: any) => x._id}
                            placeholder={t('Select')}
                            value={
                              drivers &&
                              drivers.find(
                                (x: DriverType) =>
                                  x._id === values?.consumer?._id
                              )
                            }
                            onChange={(data: any) => {
                              setFieldValue('consumerId', data._id);
                            }}
                          />
                          <ErrorMessage
                            name='consumerId'
                            component='div'
                            className='field-error text-danger text-sm'
                          />
                        </Col>
                      </Row>
                    )}
                  </TabPane>
                  <TabPane tabId='postpaidForCustomer'>
                    {values.prepaidType === 'postpaidForCustomer' && (
                      <Row>
                        <Col>
                          <label htmlFor='customerId'>
                            {t('AddOcppTags.customerId')}
                          </label>
                          <Select
                            id='customerId'
                            defaultValue={values?.consumer}
                            options={optionsLoading ? [] : drivers}
                            noOptionsMessage={() =>
                              optionsLoading ? (
                                <Spinner color='primary' className='m-auto' />
                              ) : textQuery.length < 3 ? (
                                t('ChargeStations.search')
                              ) : (
                                t('ChargeStations.noOptions')
                              )
                            }
                            onBlur={() => setFieldTouched('consumerId')}
                            onInputChange={(e: any) => {
                              setTextQuery(e);
                            }}
                            getOptionLabel={(x: any) => `${x.fname} ${x.lname}`}
                            getOptionValue={(x: any) => x._id}
                            placeholder={t('Select')}
                            value={
                              drivers &&
                              drivers.find(
                                (x: DriverType) =>
                                  x._id === values?.consumer?._id
                              )
                            }
                            onChange={(data: any) => {
                              setFieldValue('consumerId', data._id);
                            }}
                          />
                          <ErrorMessage
                            name='consumerId'
                            component='div'
                            className='field-error text-danger text-sm'
                          />
                        </Col>
                      </Row>
                    )}
                  </TabPane>
                </TabContent>
                <Row className='mt-3'>
                  <Col className='text-end'>
                    <Button
                      color='secondary'
                      type='button'
                      disabled={isSubmitting}
                      onClick={toggleHandle}
                      className='me-1'
                    >
                      {t('AddApplication.cancel')}
                    </Button>
                    <Button
                      color='primary'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      {isEditing
                        ? t('AddChargingRate.edit')
                        : t('AddChargingRate.add')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </SlidingModal>
  );
};
export default AddAdminrfids;
