import React, { useContext, useEffect, useState } from 'react';
import { Card, Spinner, Button, Row, Col, CardBody } from 'reactstrap';
import { AlertCircle, XCircle } from 'react-feather';
import { toast } from 'react-toastify';
import ModalForm from 'Components/Common/ModalForm';
import { setItem } from 'helpers/token';
import { useTranslation } from 'react-i18next';
import {
  sendOtpToDriver,
  verifyOtpToDriver,
  remoteStartTransaction,
  remoteStopTransaction,
  getLastTransaction,
  getDrivers,
  // remoteStartTransactions,
  // remoteStopTransactions,
} from 'actions';
import Select from 'react-select';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import {
  ChargeStationType,
  ConnectorType,
  DriverType,
  OcppTagType,
} from 'declerations/DefaultTypes';
import DataContext from 'Components/Contexts/DataContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

import OtpModal from './OtpModal';

interface ChargeConnectionProps {
  isOpen: boolean;
  toggleModal: (connector: ConnectorType) => void;
  chargePoint: ChargeStationType;
}

const searchSubject$ = new Subject();

const ChargeConnection = ({
  isOpen,
  toggleModal,
  chargePoint,
}: ChargeConnectionProps) => {
  const context = useContext(DataContext);
  const applicationContext = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [selectedTag, setSelectedTag] = useState<any>(null);
  const [loading, setloading] = useState<boolean>(true);
  const [error, seterror] = useState<boolean>(false);
  const [errorMessage, seterrorMessage] = useState<string>('');
  const [isDriver, setIsDriver] = useState(false);
  const [newOcppTagId, setnewOcppTagId] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedConnectorId, setselectedConnectorId] = useState<number>();
  const [showOtpModal, setshowOtpModal] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [drivers, setDrivers] = useState<any>([]);
  const [textQuery, setTextQuery] = useState('');
  const { connectors } = chargePoint;
  const MySwal = withReactContent(Swal);

  const loadDrivers = async (query: string) => {
    getDrivers({ defaultCard: 1, keyword: query })
      .then((response) => {
        setDrivers(response.data.consumers);
      })
      .catch((e) => {
        console.log('ERROR', e);
      })
      .finally(() => {
        setOptionsLoading(false);
      });
  };

  useEffect(() => {
    console.log(chargePoint);
    const { application } = chargePoint;
    const { _id: applicationId } = application;
    context
      .LoadOcppTags({
        applicationId,
      })
      .then(() => setloading(false));

    // .then(
    // async () => {
    // const { ocppTags } = context;
    // const newSelectedTag = await ocppTags?.find(
    //   (x: OcppTagType) =>
    //     x?.application?._id === applicationId &&
    //     x?.transactionState === 'Inactive' &&
    //     x?.isDeleted === false
    // );
    // if (!newSelectedTag) {
    //   seterror(true);
    //   setloading(false);
    //   seterrorMessage(t('ChargeConnection.errorMessage'));
    // } else {
    //   setSelectedTag(newSelectedTag);
    //   setloading(false);
    // }
    // });
    const searchSubscription = searchSubject$
      .asObservable()
      .pipe(distinctUntilChanged(), debounceTime(1500))
      .subscribe((query: any) => {
        if (query.length < 3) {
          setOptionsLoading(false);
          return;
        }
        loadDrivers(query);
      });
    return () => {
      searchSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (textQuery.length > 2) {
      setOptionsLoading(true);
      searchSubject$.next(textQuery);
    } else {
      searchSubject$.next('');
      setDrivers([]);
    }
  }, [textQuery]);

  const startCharging = async (connectorId?: number) => {
    const { chargePointId } = chargePoint;
    if (isDriver && !newOcppTagId) {
      toast.error(t('ChargeStations.pleaseChooseDriver'));
      return;
    }
    const uid = `${Math.floor(Math.random() * Date.now())}`;
    const m = {
      type: 'REMOTE_START',
      data: {
        chargePointId,
        action: 'PrepareRemoteStartTransaction',
        messageTypeId: 2,
        uniqueId: uid,
        payload: {
          idTag: newOcppTagId, // "TEST001",
          connectorId,
        },
      },
    };

    remoteStartTransaction(m.data.chargePointId, m.data.payload)
      .then((res) => console.log(res))
      // .then((res: { status: string }) => {
      //   if (res.status === 'Accepted') {
      //     toast.success(
      //       t('ChargeConnection.chargeStationAcceptedSuccessfully')
      //     );
      //   } else if (res.status === 'Rejected') {
      //     toast.success(t('ChargeConnection.chargeStationRejected'));
      //   } else {
      //     throw new Error(''); // unexpected
      //   }
      // })
      .catch((e) => {
        toast.error(t('ChargeConnection.oppsSomethingWentWrong'));
      });
  };
  const stopCharging = async (connectorId?: number) => {
    const { application, chargePointId } = chargePoint;

    getLastTransaction({ chargePointId, connectorId })
      .then((response) => {
        const txId = response.data.transactionId;
        const m = {
          type: 'REMOTE_STOP',
          data: {
            chargePointId,
            action: 'RemoteStopTransaction',
            messageTypeId: 2,
            uniqueId: txId,
            payload: {
              transactionId: txId,
            },
          },
        };

        remoteStopTransaction(m.data.chargePointId, m.data.payload)
          .then((res) => console.log(res))
          // .then((res) => {
          //   if (res?.status === 'Accepted') {
          //     toast.success(
          //       t('ChargeConnection.chargeStationAcceptedSuccessfully')
          //     );
          //   } else if (res?.status === 'Rejected') {
          //     toast.success(t('ChargeConnection.chargeStationRejected'));
          //   } else {
          //     throw new Error('');
          //   }
          // })
          .catch((e) => {
            toast.error(t('ChargeConnection.oppsSomethingWentWrong'));
          });
      })
      .catch((response) => toast.error('Error on getting TransactionId'));
  };

  const startOtpVerification = (connectorId?: number) => {
    if (isDriver && !selectedDriver) {
      toast.error(t('ChargeStations.pleaseChooseDriver'));
      return;
    }
    if (newOcppTagId === null) {
      toast.error(t('Choose ocpptag'));
    }
    if (!isDriver) {
      startCharging(connectorId);
      return;
    }
    sendOtpToDriver({
      idTag: newOcppTagId,
    }).then((response) => setshowOtpModal(true));
  };
  const checkOtpVerification = (connectorId: number, otp: string) => {
    verifyOtpToDriver({ idTag: newOcppTagId, otp })
      .then((response) => {
        if (response.data && response.data.verified) {
          setshowOtpModal(false);
          return startCharging(connectorId);
        }
        throw new Error('wrong otp');
      })
      .catch((e) => toast.error('Otp is wrong!'));
  };

  const showStartButton = (connector: ConnectorType) => {
    const x = connector.status.status;
    const availableStatuses = ['Preparing', 'Available']; // avaliable or preparing
    if (!availableStatuses.includes(x)) {
      return <div />;
    }
    return (
      <Button
        color='success'
        disabled={newOcppTagId == null}
        onClick={() => {
          setselectedConnectorId(connector.connectorId);
          MySwal.fire({
            title: t('ChargeConnection.chargeStationIsStartingAreyouSure'),
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: t('no'),
            confirmButtonText: t('yes'),
          }).then((res) => {
            if (res.isConfirmed) {
              startOtpVerification(connector.connectorId);
            }
          });
        }}
      >
        {t('ChargeConnection.start')}
      </Button>
    );
  };

  const showStopButton = (connector: ConnectorType) => {
    const x = connector.status.status;
    const availableStatuses = ['SuspendedEvse', 'SuspendedEV', 'Charging'];

    if (!availableStatuses.includes(x)) {
      return <div />;
    }
    return (
      <Button
        color='danger'
        onClick={() => {
          setselectedConnectorId(connector.connectorId);
          MySwal.fire({
            title: t('ChargeConnection.chargeStationIsStoppingAreyouSure'),
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: t('no'),
            confirmButtonText: t('yes'),
          }).then((res) => {
            if (res.isConfirmed) {
              stopCharging(selectedConnectorId);
            }
          });
        }}
      >
        {t('ChargeConnection.stop')}
      </Button>
    );
  };

  return (
    <div>
      <ModalForm
        title={t('ChargeConnection.connecttoChargeStation')}
        isOpen={isOpen}
        size='md'
        toggleModal={toggleModal}
      >
        {loading ? (
          <Card
            className='text-center d-flex align-middle'
            style={{ minHeight: '200px' }}
          >
            <Spinner color='primary' className='m-auto' />
          </Card>
        ) : error ? (
          <Card style={{ minHeight: '200px' }}>
            <CardBody className='align-middle text-center d-flex'>
              <div className='m-auto'>
                <XCircle size='80px' className='mb-1' color='#f00' />
                <div className='danger text-danger'>
                  {errorMessage || 'Error on connection !'}
                </div>
              </div>
            </CardBody>
          </Card>
        ) : (
          <>
            <div className='flex-row d-flex my-1'>
              <div className='my-auto me-2'>
                {t('ChargeStations.chooseChargeKey')}
              </div>
              <Toggle
                icons={false}
                defaultChecked={isDriver}
                onChange={() => setIsDriver(!isDriver)}
              />
              <div className='my-auto ms-2'>
                {t('ChargeStations.chooseDriver')}
              </div>
            </div>
            {!isDriver && (
              <Select
                className='my-2'
                options={context.ocppTags}
                getOptionLabel={(x: OcppTagType) => `${x.idTag} `}
                getOptionValue={(x: any) => x.idTag}
                placeholder={t('ChargeStations.chooseChargeKey')}
                onChange={(data: any) => setnewOcppTagId(data?.idTag)}
              />
            )}

            {isDriver && (
              <>
                <p className='text-muted'>
                  {t('ChargeStations.searchInfo')}
                </p>
                <Select
                  noOptionsMessage={() =>
                    optionsLoading ? (
                      <Spinner color='primary' className='m-auto' />
                    ) : textQuery.length < 3 ? (
                      t('ChargeStations.search')
                    ) : (
                      t('ChargeStations.noOptions')
                    )
                  }
                  onInputChange={(e: any) => {
                    setTextQuery(e);
                  }}
                  className='my-2'
                  options={optionsLoading ? [] : drivers}
                  getOptionLabel={(x): any =>
                    `${x.fname} ${x.lname} ${x.phone} ${x.ocppTagId}`
                  }
                  getOptionValue={(x) => x.ocppTagId}
                  placeholder={t('ChargeStations.selectDriver')}
                  value={
                    drivers &&
                    drivers.find(
                      (x: DriverType) => x.ocppTagId === newOcppTagId
                    )
                  }
                  onChange={(data) => {
                    setnewOcppTagId(data.ocppTagId);
                    setSelectedDriver(data);
                  }}
                />
              </>

              // setnewOcppTagId(data.ocppTagId)
            )}
            {connectors && connectors.length > 0 ? (
              connectors.map((connector, idx) => (
                <Card key={idx.toString()}>
                  <CardBody>
                    <Row>
                      <Col xl={7}>
                        <h4>
                          <b>{t('ChargeConnection.connectorNumber')}</b>{' '}
                          {connector.connectorId}
                        </h4>
                        {connector.status && connector.status.status && (
                          <h5>
                            <b>{t('ChargeConnection.connectorStatus')}</b>{' '}
                            {connector.status.status}{' '}
                          </h5>
                        )}
                        <h5>
                          <b>{t('ChargeConnection.level')}</b> {connector.level}{' '}
                        </h5>
                        <h5>
                          <b>{t('ChargeConnection.socketType')}</b>{' '}
                          {connector.socketType}{' '}
                        </h5>
                        <h5>
                          <b>{t('ChargeConnection.maxPower')}</b>{' '}
                          {connector.maxPowerKW} KW{' '}
                        </h5>
                      </Col>
                      <Col xl={5}>
                        {connector &&
                          connector.status &&
                          connector.status.status && (
                            <>
                              <Row className='my-1'>
                                <Col>{showStartButton(connector)}</Col>
                              </Row>
                              <Row className='my-1'>
                                <Col>{showStopButton(connector)}</Col>
                              </Row>
                            </>
                          )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))
            ) : (
              <Card>
                <CardBody className='align-middle text-center d-flex'>
                  <div className='m-auto'>
                    <AlertCircle size='80px' className='mb-1' color='#f00' />
                    <div className='danger text-danger'>
                      {t(
                        'ChargeConnection.thereisNoConnectorsonThisChargeStation'
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </>
        )}
        {showOtpModal && (
          <OtpModal
            isOpen={showOtpModal}
            toggleModal={() => setshowOtpModal(!showOtpModal)}
            checkOtpVerification={checkOtpVerification}
            connectorId={selectedConnectorId || 1}
          />
        )}
      </ModalForm>
    </div>
  );
};

export default ChargeConnection;
