import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import { renderField, renderSelectField } from 'Components/Common/Fields';
import * as Yup from 'yup';
import { Button, Row, Col, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import EditContext from 'Components/Contexts/EditContext';
import SlidingModal from 'Components/Common/SlidingModal';
import i18n from 'i18next';
import DataContext from 'Components/Contexts/DataContext';
import { ApplicationsType, DomainsType } from 'declerations/DefaultTypes';
import { editApplication, createApplication } from 'actions';
import currencies from 'helpers/Currencies';
import SelectBox from 'Components/Common/SelectBox';
import Select from 'react-select';
import { max } from 'lodash';

const formSchema = () =>
  Yup.object().shape({
    name: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    companyName: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    taxOffice: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    taxNumber: Yup.string()
      .min(0, 'Required')
      .max(11, i18n.t('Must be exactly 11 characters'))
      .required(i18n.t('Required')),

    ibanNumber: Yup.string().transform((value) =>
      // eslint-disable-next-line no-restricted-globals
      isNaN(value) || value === null || value === undefined ? '' : value
    ),
    //   .test(
    //     'ibanNumber',
    //     i18n.t('must be proper iban number'),
    //     (ibanNumber) => {
    //       return isValidIBAN(ibanNumber);
    //     }
    //   ),
    address: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    phone: Yup.string()
      .min(1, 'Required')
      .matches(/^[0-9+]+$/, i18n.t('AddApplication.mustbeValidPhoneNumber'))
      .required(i18n.t('Required')),
    email: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    // domainId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    currency: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    code: Yup.string().transform((value) =>
      // eslint-disable-next-line no-restricted-globals
      isNaN(value) || value === null || value === undefined ? '' : value
    ),

    revenueSharePercentage: Yup.number()
      .min(0, i18n.t('AddApplication.mustbeMoreThanZero'))
      .max(100, i18n.t('AddApplication.mustbeLessThanhundred'))
      .transform((value) =>
        // eslint-disable-next-line no-restricted-globals
        isNaN(value) || value === null || value === undefined ? 0 : value
      ),
  });
const formSchemaAdd = () =>
  Yup.object().shape({
    name: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    companyName: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    taxOffice: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    taxNumber: Yup.string()
      .min(0, 'Required')
      .max(11, i18n.t('Must be exactly 11 characters'))
      .required(i18n.t('Required')),

    ibanNumber: Yup.string(),
    //   .test(
    //     'ibanNumber',
    //     i18n.t('must be proper iban number'),
    //     (ibanNumber) => {
    //       return isValidIBAN(ibanNumber);
    //     }
    //   ),
    address: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    phone: Yup.string()
      .min(1, 'Required')
      .matches(/^[0-9+]+$/, i18n.t('AddApplication.mustbeValidPhoneNumber'))
      .required(i18n.t('Required')),
    email: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    // domainId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    currency: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    code: Yup.string(),

    revenueSharePercentage: Yup.number()

      .positive(i18n.t('AddApplication.mustbeMoreThanZero'))
      .max(100, i18n.t('AddApplication.mustbeLessThanhundred'))
      .transform((value) =>
        // eslint-disable-next-line no-restricted-globals
        isNaN(value) || value === null || value === undefined ? 0 : value
      ),
  });

interface AddCpoProps {
  isOpen: boolean;
  isEditing: boolean;
  toggleHandle: () => void;
}
const AddCpo = ({ isOpen, toggleHandle, isEditing }: AddCpoProps) => {
  const context = useContext(DataContext);
  const editContext = useContext(EditContext);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    name: '',
    domainId: '',
    currency: '',
    companyName: '',
    taxOffice: '',
    taxNumber: '',
    ibanNumber: '',
    address: '',
    phone: '',
    email: '',
    code: '',
    revenueSharePercentage: '',
  });

  const { t } = useTranslation();
  useEffect(() => {
    context.LoadDomains();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (isEditing) {
      const {
        name = '',
        currency = '',
        companyName = '',
        taxOffice = '',
        taxNumber = '',
        ibanNumber = '',
        address = '',
        phone = '',
        email = '',
        code = '',
        revenueSharePercentage = '',
      } = editContext.editApplication;
      setInitialValues({
        name,
        domainId: context.domains?.map((x: DomainsType) => x._id)[0] || '',
        currency,
        companyName,
        taxOffice,
        taxNumber,
        ibanNumber,
        address,
        phone,
        email,
        code,
        revenueSharePercentage,
      });
    } else {
      setInitialValues({
        name: '',
        domainId: context.domains?.map((x: DomainsType) => x._id)[0] || '',
        currency: '',
        companyName: '',
        taxOffice: '',
        taxNumber: '',
        ibanNumber: '',
        address: '',
        phone: '',
        email: '',
        code: '',
        revenueSharePercentage: '',
      });
    }
  }, [isEditing, context.domains]);

  return (
    <SlidingModal
      toggleHandle={toggleHandle}
      isOpen={isOpen}
      title={t('AddCpo.AddCpo')}
      size='lg'
    >
      {!loading && isOpen ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={isEditing ? formSchema : formSchemaAdd}
          onSubmit={async (values) => {
            if (isEditing) {
              const { _id: id } = editContext.editApplication;
              const { domainId, ...newValues } = values;
              return editApplication(id, newValues)
                .then(() => {
                  toast.success(
                    t('AddApplication.appicationEditedSuccesssfully')
                  );
                  return toggleHandle();
                })
                .catch(() =>
                  toast.error(t('AddApplication.unabletoEditAppication'))
                );
            }
            return createApplication(values)
              .then(() => {
                toast.success(
                  t('AddApplication.appicationCreatedSuccesssfully')
                );
                setInitialValues({
                  name: '',
                  domainId:
                    context.domains?.map((x: DomainsType) => x._id)[0] || '',
                  currency: '',
                  companyName: '',
                  taxOffice: '',
                  taxNumber: '',
                  ibanNumber: '',
                  address: '',
                  phone: '',
                  email: '',
                  code: '',
                  revenueSharePercentage: '',
                });
                return toggleHandle();
              })
              .catch(() =>
                toast.error(t('AddApplication.unabletoCreateApplication'))
              );
          }}
        >
          {({
            values,
            touched,
            errors,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            setFieldTouched,
          }) => {
            useMemo(
              () =>
                Object.keys(errors).forEach((fieldName) => {
                  setFieldTouched(fieldName);
                }),
              [i18n.language]
            );
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='name'
                      label={t('AddApplication.name')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.name,
                        error: errors.name,
                      }}
                    />
                  </Col>
                  {/* <Col>
                    <label htmlFor='domains'>
                      {t('AddApplication.domains')}
                    </label>
                    <Select
                      id='domains'
                      isDisabled={isEditing}
                      options={context.domains}
                      placeholder={t('Select')}
                      getOptionLabel={(x: any) => x.name}
                      getOptionValue={(x) => x._id}
                      value={
                        context.domains &&
                        context.domains.find(
                          (x: any) => x._id === values.domainId
                        )
                      }
                      onChange={(data) => {
                        setFieldValue('domainId', data._id);
                      }}
                    />
                    <ErrorMessage
                      name='domainId'
                      component='div'
                      className='text-danger text-sm'
                    />
                  </Col> */}
                  <Col>
                    <label htmlFor='currency'>
                      {t('AddApplication.currency')}
                    </label>
                    <Select
                      id='currency'
                      options={currencies}
                      getOptionLabel={(x) => `${x.label} ( ${x.symbol} )`}
                      getOptionValue={(x) => x.value}
                      placeholder={t('Select')}
                      value={currencies.find(
                        (x) => x.value === values.currency
                      )}
                      onChange={(data: any) => {
                        setFieldValue('currency', data.value);
                      }}
                    />
                    <ErrorMessage
                      name='currency'
                      component='div'
                      className='field-error text-danger text-sm'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='companyName'
                      label={t('AddApplication.companyName')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.companyName,
                        error: errors.companyName,
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderField}
                      name='taxOffice'
                      label={t('AddApplication.taxOffice')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.taxOffice,
                        error: errors.taxOffice,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='taxNumber'
                      label={t('AddApplication.taxNumber')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.taxNumber,
                        error: errors.taxNumber,
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderField}
                      name='ibanNumber'
                      label={t('AddApplication.ibanNumber')}
                      className='form-control'
                      type='text'
                      placeholder={t('AddApplication.ibanNumbers')}
                      meta={{
                        touched: touched.ibanNumber,
                        error: errors.ibanNumber,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='code'
                      label={t('AddApplication.code')}
                      placeholder={t('AddApplication.code')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.code,
                        error: errors.code,
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderField}
                      name='revenueSharePercentage'
                      label={t('AddApplication.revenueSharePercentage')}
                      className='form-control'
                      type='number'
                      placeholder={t('AddApplication.revenueSharePercentage')}
                      meta={{
                        touched: touched.revenueSharePercentage,
                        error: errors.revenueSharePercentage,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='phone'
                      label={t('AddApplication.phone')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.phone,
                        error: errors.phone,
                      }}
                      placeholder='+905551230012'
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderField}
                      name='email'
                      label={t('AddApplication.email')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.email,
                        error: errors.email,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='address'
                      label={t('Application.addresses')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.address,
                        error: errors.address,
                      }}
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col className='text-end'>
                    <Button
                      color='secondary'
                      type='button'
                      disabled={isSubmitting}
                      onClick={toggleHandle}
                      className='me-1'
                    >
                      {t('AddApplication.cancel')}
                    </Button>
                    <Button
                      color='primary'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      {isEditing
                        ? t('AddApplication.edit')
                        : t('AddApplication.add')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </SlidingModal>
  );
};
export default AddCpo;
