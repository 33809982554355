import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
export const getProfile = () => {
  const apiUrl = `profile/`;

  return api.get(apiUrl);
};

export const editProfile = (params: object) => {
  const apiUrl = `profile`;
  return api.update(apiUrl, params);
};
