import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';
import Toggle from 'react-toggle';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import Container from 'Components/Common/Container';
import SearchInput from 'Components/Common/SearchInput';
import ExportBlock from 'laxyExcel/ExportComponents';
import { toast } from 'react-toastify';
import { Plus, Trash2, Edit3 } from 'react-feather';
import { editEmployee } from 'actions/employees';
import EditContext from 'Components/Contexts/EditContext';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { AUTO_REFRESH_TIME } from 'helpers/config';

import { EmployeeType } from '../../../declerations/DefaultTypes';
import AddEmployee from './AddEmployee/AddEmployee';

const Employees = () => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const [filtered, setFiltered] = useState('');
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [isOpenEdit, setisOpenEdit] = useState(false);
  const editContext = useContext(EditContext);

  const headers = ['fname', 'lname', 'email'].map((key: string) => ({
    key,
    value: `employes.${key}`,
  }));

  const loadDataTable = () => {
    context.LoadEmployees().then(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    loadDataTable();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);
    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh]);

  const search = (rowData: EmployeeType[] | undefined) => {
    if (!rowData) {
      return [];
    }
    return rowData.filter(
      (row: EmployeeType) =>
        row?.fname?.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
        row?.lname?.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
        row?.email?.toLowerCase().indexOf(filtered.toLowerCase()) > -1
    );
  };

  return (
    <Container
      title={t('Employees')}
      rightSide={
        <AutoRefresh
          isActive={isActiveAutoRefresh}
          onPress={() => {
            setIsActiveAutoRefresh(!isActiveAutoRefresh);
          }}
        />
      }
    >
      <Row className='mb-2 d-flex justify-content-end'>
        <Col xl={3} sm={12} className='d-flex'>
          <SearchInput onChange={(text) => setFiltered(text)} />
        </Col>
        <Col xl={6} sm={12}>
          {' '}
        </Col>
        <Col
          xl={3}
          sm={12}
          className='d-flex justify-content-end '
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <ExportBlock
            data={context.employees}
            header={headers}
            name={t('Employees')}
          />
          <Button
            className='ms-1'
            aria-hidden='true'
            color='primary'
            onClick={() => {
              setisOpenEdit(false);
              setAddIsOpen(!addIsOpen);
            }}
          >
            <Plus className='me-2 p-0' size='20' />
            {t('Add')}
          </Button>
        </Col>
      </Row>
      <div>
        <DataTable
          loading={loading}
          noDataText={t('Employes.thereArenoEmployeesCreated')}
          columns={[
            {
              name: 'Email',
              selector: (row: EmployeeType) => row.email,
            },
            {
              name: t('Employes.fname'),
              selector: (row: EmployeeType) => row.fname,
            },
            {
              name: t('Employes.lname'),
              selector: (row: EmployeeType) => row.lname,
            },
            {
              name: t('Employes.enabled'),
              cell: (props: EmployeeType) => {
                return (
                  <div className='m-atuo align-middle d-flex'>
                    <Toggle
                      icons={false}
                      defaultChecked={!props.isBlocked}
                      onChange={(e) => {
                        return editEmployee(props._id, {
                          isBlocked: !e.target.checked,
                        })
                          .then((response) => {
                            context.LoadEmployees();

                            toast.success(
                              t('Employes.employeeEditedSuccessfully')
                            );
                          })
                          .catch((err) =>
                            toast.error(t('Employes.unabletoEditEmployee'))
                          );
                      }}
                      className='switch-danger'
                    />
                  </div>
                );
              },
            },
            {
              name: t('OCPPtags.action'),

              cell: (row: EmployeeType) => {
                return (
                  <div>
                    <Edit3
                      className='mr-1'
                      cursor='pointer'
                      onClick={() => {
                        editContext.SetEditEmployee(row);
                        setisOpenEdit(true);
                        setAddIsOpen(true);
                      }}
                    />
                    {/* <Trash2
                      color='#f00'
                      className='mr-1'
                      cursor='pointer'
                      onClick={(e) => {
                        this.setState({
                          isBlockPromptVisible: true,
                          isBlockPromptTitle: t(
                            'OCPPtags.doYouWanttoDeleteThisOCPPtag'
                          ),
                          isBlockPromptOnSubmit: () => {
                            this.setState({ isBlockPromptVisible: false });
                            deleteOcppTag(row._id).then((res) => {
                              res.isSuccess == true
                                ? toast.success('Basaılı')
                                : toast.error('Fail');
                            });
                          },
                        });
                      }}
                    /> */}
                  </div>
                );
              },
            },
          ]}
          data={search(context.employees) || []}
        />
      </div>
      <AddEmployee
        isOpen={addIsOpen}
        isEditing={isOpenEdit}
        toggleHandle={() => {
          setAddIsOpen(false);
          setisOpenEdit(false);
          context.LoadEmployees();
        }}
      />
    </Container>
  );
};
export default Employees;
