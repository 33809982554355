/* eslint-disable */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
// Import Data
import { withTranslation } from 'react-i18next';
import navdata from './LayoutMenuData';
// i18n

const SidebarContent = (props) => {
  const navData = navdata().props.children;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const initMenu = () => {
      const pathName = process.env.PUBLIC_URL + props.location.pathname;
      const ul = document.getElementById('navbar-nav');
      const items = ul.getElementsByTagName('a');
      const itemsArray = [...items]; // converts NodeList to Array
      removeActivation(itemsArray);
      // if (matchingMenuItem) {
      //   activateParentDropdown(matchingMenuItem);
      // }
    };
    if (props.layoutType === 'vertical') {
      initMenu();
    }
  }, [props.location.pathname, props.layoutType]);

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parentCollapseDiv = item.closest('.collapse.menu-dropdown');

    if (parentCollapseDiv) {
      // to set aria expand true remaining
      parentCollapseDiv.classList.add('show');
      parentCollapseDiv.parentElement.children[0].classList.add('active');
      parentCollapseDiv.parentElement.children[0].setAttribute(
        'aria-expanded',
        'true'
      );
      if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown')) {
        parentCollapseDiv.parentElement
          .closest('.collapse')
          .classList.add('show');
        if (
          parentCollapseDiv.parentElement.closest('.collapse')
            .previousElementSibling
        )
          parentCollapseDiv.parentElement
            .closest('.collapse')
            .previousElementSibling.classList.add('active');
      }
      return false;
    }
    return false;
  }
  const closeBar = () => {
    document.body.classList.remove('vertical-sidebar-enable');
  };
  const removeActivation = (items) => {
    const actiItems = items.filter((x) => x.classList.contains('active'));

    actiItems.forEach((item) => {
      if (item.classList.contains('menu-link')) {
        if (!item.classList.contains('active')) {
          item.setAttribute('aria-expanded', false);
        }
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove('show');
        }
      }
      if (item.classList.contains('nav-link')) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove('show');
        }
        item.setAttribute('aria-expanded', false);
      }
      item.classList.remove('active');
    });
  };

  return (
    <>
      {/* menu Items */}
      {(navData || []).map((item, key) => {
        return (
          <React.Fragment key={key}>
            {/* Main Header */}
            {item.isHeader ? (
              <li className='menu-title'>
                <span data-key='t-menu'>{props.t(item.label)}</span>
              </li>
            ) : item.subItems ? (
              <li className='nav-item nonlink'>
                <a
                  // onClick={item.click}
                  style={{ cursor: 'default' }}
                  className='nav-link menu-link nonlink'
                  // to={item.link ? item.link : null}
                  // data-bs-toggle='collapse'
                >
                  {item.icon}{' '}
                  <span data-key='t-apps'>{props.t(item.label)}</span>
                </a>
                <Collapse
                  className='menu-dropdown'
                  isOpen={true}
                  id='sidebarApps'
                >
                  <ul className='nav nav-sm flex-column test nonlink'>
                    {/* subItms  */}
                    {item.subItems &&
                      (item.subItems || []).map((subItem, key) => (
                        <React.Fragment key={key}>
                          {!subItem.isChildItem ? (
                            <>
                              {!subItem.isHidden && (
                                <li className='nav-item' onClick={closeBar}>
                                  <Link
                                    to={subItem.link ? subItem.link : null}
                                    className={`nav-link ${
                                      props?.location?.pathname === subItem.link
                                        ? 'active2'
                                        : ''
                                    }`}
                                  >
                                    {props.t(subItem.label)}
                                  </Link>
                                </li>
                              )}
                            </>
                          ) : (
                            <li className='nav-item nonlink'>
                              <a
                                // onClick={subItem.click}
                                className='nav-link '
                                style={{ cursor: 'default' }}

                                // data-bs-toggle='collapse'
                              >
                                {' '}
                                {props.t(subItem.label)}
                              </a>
                              <Collapse
                                className='menu-dropdown'
                                isOpen={subItem.stateVariables}
                                id='sidebarEcommerce'
                              >
                                <ul className='nav nav-sm flex-column'>
                                  {/* child subItms  */}

                                  {subItem.childItems &&
                                    (subItem.childItems || []).map(
                                      (childItem, key) => (
                                        <li className='nav-item' key={key}>
                                          <Link
                                            to={
                                              childItem.link
                                                ? childItem.link
                                                : '/#'
                                            }
                                            className={`nav-link ${
                                              props.location.pathname ===
                                              childItem.link
                                                ? 'active2'
                                                : ''
                                            }`}
                                          >
                                            {props.t(childItem.label)}
                                          </Link>
                                        </li>
                                      )
                                    )}
                                </ul>
                              </Collapse>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                  </ul>
                </Collapse>
              </li>
            ) : item.isVisible === false ? null : (
              <li className='nav-item'>
                <Link
                  className={`nav-link menu-link ${
                    props?.location?.pathname === item.link ? 'active2' : ''
                  }`}
                  to={item.link ? item.link : '/#'}
                >
                  {item.icon} <span>{props.t(item.label)}</span>
                </Link>
              </li>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
