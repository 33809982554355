import React from 'react';
import mime from 'mime-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
const axiosInstance = axios.create();

export const getChargeStations = (filter?: object) => {
  const apiUrl = `chargepoints`;
  return api.get(apiUrl, filter);
};

export const getAlarms = (filter?: object) => {
  const apiUrl = `alarms`;
  return api.get(apiUrl, filter);
};

export const getDashboard = () => {
  const apiUrl = `chargepoints/dashboard`;
  return api.get(apiUrl);
};

export const getStatisticsCount = (filter?: object) => {
  const apiUrl = `statistics/counts`;
  return api.get(apiUrl, filter);
};

export const getBestPerforming = (filter?: object) => {
  const apiUrl = `statistics/best-performing`;
  return api.get(apiUrl, filter);
};

export const getRevenueReport = (filter: object) => {
  const apiUrl = `statistics/revenue`;
  return api.get(apiUrl, filter);
};
export const getRevenue = (filter: object) => {
  const apiUrl = `statistics/usage`;
  return api.get(apiUrl, filter);
};
export const editChargeStation = (id: string, params: object) => {
  const apiUrl = `chargepoints/${id}`;
  return api.update(apiUrl, params);
};

export const getChargeStationDetails = (id: string) => {
  const apiUrl = `chargepoints/${id}`;
  return api.get(apiUrl);
};
export const getFilterChargeStation = (id: string) => {
  const apiUrl = `chargepoints?locationId=${id}`;

  return api.get(apiUrl);
};
export const deleteChargeStationPhoto = async (id: string, params: object) => {
  const apiUrl = `chargepoints/${id}/images/`;
  return api.update(apiUrl, params);
};

export const uploadChargeStationPhoto = async (
  id: string,
  file: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: any
) => {
  const apiUrl = `chargepoints/${id}/images/`;
  const fileType = file?.type;
  const extension = mime.extension(fileType);
  const data: {
    isSuccess?: boolean;
    data: {
      cpImagePostObj: { fields: object; url: string };
      cpImageUrl?: string;
      imageUrl?: never;
    };
  } = await api.create(apiUrl, {
    cpImage: {
      extension,
    },
  });
  if (!data || !data.isSuccess) {
    return data;
  }
  const { cpImagePostObj, cpImageUrl } = data.data;
  const { fields, url } = cpImagePostObj;
  const formData = new FormData();
  Object.entries(fields).forEach(([key, value]) => {
    // if (typeof value === 'string' || typeof value === typeof Blob)
    formData.set(key, value);
  });
  formData.set('file', file);
  let toastId: React.ReactText = '';
  try {
    const response = await axiosInstance.request({
      method: 'POST',
      url,
      data: formData,
      onUploadProgress: (p: { loaded: number; total: number }) => {
        const progress = p.loaded / p.total;
        // check if we already displayed a toast
        if (toastId) {
          toast.update(toastId, {
            progress,
          });
        } else {
          toastId = toast.info(t('ChargeStationActions.uploadInProgress'), {
            progress,
          });
        }
      },
    });
    return { isSuccess: true, data: { imageUrl: cpImageUrl } };
  } catch (e) {
    toast.error(t('ChargeStationActions.failedToUploadPhoto'));
    return { isSuccess: false };
  } finally {
    if (toastId) {
      toast.dismiss(toastId);
    }
  }
};

export const updatePrice = (
  chargePointId: string,
  connectorId: string,
  params: any
) => {
  const apiUrl = `chargepoints/${chargePointId}/connectors/${connectorId}`;
  return api.update(apiUrl, params);
};

export const createChargeStation = (params: object) => {
  const apiUrl = `chargepoints`;
  return api.create(apiUrl, params);
};

export const getDeviceLogs = (params: object) => {
  const apiUrl = `device-logs/`;
  return api.get(apiUrl, params);
};

export const getMeterValues = (params: {
  chargePointId: string;
  limit: number;
  offset: number;
}) => {
  const apiUrl = `chargepoints/logs/${params.chargePointId}/meter?skip=${params.offset}&limit=${params.limit}&`;
  return api.get(apiUrl);
};

export const deleteChargeStation = (id?: string) => {
  const apiUrl = `chargepoints/${id}`;
  return api.delete(apiUrl);
};

export const createRepairLog = (id: string, params: object) => {
  const apiUrl = `chargepoints/logs/${id}/repair`;
  return api.create(apiUrl, params);
};
export const updateRepairLog = (id: string, _id: string, params: object) => {
  const apiUrl = `chargepoints/logs/${id}/repair/${_id}`;
  return api.update(apiUrl, params);
};
export const getRepairLog = (id: string) => {
  const apiUrl = `chargepoints/logs/${id}/repair`;
  return api.get(apiUrl);
};
export const deleteRepairLog = (id: string, _id: string) => {
  const apiUrl = `chargepoints/logs/${id}/repair/${_id}`;
  return api.delete(apiUrl);
};

export const getBrands = () => {
  const apiUrl = `brands`;
  return api.get(apiUrl);
};

export const getSyncBrands = () => {
  const apiUrl = `brands/sync`;
  return api.get(apiUrl);
};

export const getModels = (brand: string) => {
  const apiUrl = `brands/${brand}/models`;
  return api.get(apiUrl);
};

export const getVariants = (brand: string, model: string) => {
  const apiUrl = `brands/${brand}/models/${model}/variants`;
  return api.get(apiUrl);
};

export const getChargePointConnectors = (
  brand: string,
  model: string,
  variant: string
) => {
  const apiUrl = `brands/${brand}/models/${model}/variants/${variant}`;
  return api.get(apiUrl);
};

export const getConfigurations = (applicationId: string, brandId: string) => {
  const apiUrl = `ccps/search`;
  return api.create(apiUrl, { applicationId, brandId });
};

export const getCpConfiguration = (id?: string) => {
  const apiUrl = `chargepoints/${id}/OCPP/GetConfiguration`;
  return api.create(apiUrl);
};

export const handleReset = (id?: string, params?: any) => {
  const apiUrl = `chargepoints/${id}/OCPP/Reset`;
  return api.create(apiUrl, params);
};

export const remoteStartTransaction = (id?: string, params?: any) => {
  const apiUrl = `chargepoints/${id}/OCPP/RemoteStartTransaction`;
  return api.create(apiUrl, params);
};
export const remoteStopTransaction = (id?: string, params?: any) => {
  const apiUrl = `chargepoints/${id}/OCPP/RemoteStopTransaction`;
  return api.create(apiUrl, params);
};
export const changeAvailabilityy = (id?: string, params?: any) => {
  const apiUrl = `chargepoints/${id}/OCPP/ChangeAvailibility`;
  return api.create(apiUrl, params);
};
export const unlockConnector = (id?: string, params?: any) => {
  const apiUrl = `chargepoints/${id}/OCPP/UnlockConnector`;
  return api.create(apiUrl, params);
};
