/* eslint-disable import/prefer-default-export */

interface TransactionStatus {
  title: string;
  color: string;
}

export enum TransactionStatusEnum {
  REFUNDED = 'REFUNDED',
  CHARGING = 'CHARGING',
  FAILED = 'FAILED',
  PAID = 'PAID',
}

export enum TransactionStatusColorEnum {
  REFUNDED = '#8772f9',
  CHARGING = '#878a99',
  FAILED = '#DC2626',
  PAID = '#16A34A',
  FREE = '#25A0E2',
  NOT_CHARGED = '#F59E0B',
}

export const handleTransactionStatus = (
  transaction: any
): TransactionStatus => {
  if (transaction.isRefunded) {
    return {
      title: 'Transaction.refunded',
      color: TransactionStatusColorEnum.REFUNDED,
    };
  }
  if (transaction.inProgress) {
    return {
      title: 'Transaction.chargingLabel',
      color: TransactionStatusColorEnum.CHARGING,
    };
  }
  if (
    transaction.paymentProfile?.paymentStatus === TransactionStatusEnum.FAILED
  ) {
    return {
      title: 'Transaction.notPaid',
      color: TransactionStatusColorEnum.FAILED,
    };
  }
  if (transaction.paymentProfile?.totalFee === 0) {
    return {
      title: 'Transaction.free',
      color: TransactionStatusColorEnum.FREE,
    };
  }
  if (
    transaction.paymentProfile?.totalFee < 1 &&
    transaction.paymentProfile?.totalFee > 0
  ) {
    return {
      title: 'Transaction.notCharged',
      color: TransactionStatusColorEnum.NOT_CHARGED,
    };
  }
  return { title: 'Transaction.paid', color: TransactionStatusColorEnum.PAID };
};
