import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Formik, Form, ErrorMessage, Field, FieldArray, getIn } from 'formik';
import {
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Label,
  Spinner,
  Progress,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import * as Yup from 'yup';
import Select, { SingleValue } from 'react-select';
import {
  createChargeStation,
  getChargePointConnectors,
  getConfiguration,
  getConfigurations,
  updatePrice,
} from 'actions';
import DataContext from 'Components/Contexts/DataContext';
import EditContext from 'Components/Contexts/EditContext';
import ModalForm from 'Components/Common/ModalForm';
import SlidingModal from 'Components/Common/SlidingModal';
import CardInfoText from 'Components/Common/CardInfoText';
import {
  ApplicationsType,
  ChargingRatesType,
  LocationType,
  SocketType,
} from 'declerations/DefaultTypes';
import {
  renderField,
  renderSelectField,
  renderTextArea,
} from 'Components/Common/Fields';
import ApplicationContext from 'Components/Contexts/ApplicationContext';

import ChooseFromMap from './ChooseFromMap';
import configurationData from './configuration.json';

interface ConnectorProps {
  level: string;
  socketType: string;
  maxPowerKW: string;
  rateId: string;
  isCabled: boolean;
  applyParking: boolean;
}

interface InitialValuesProps {
  applicationId: string;
  registrationStatus: string;
  chargePointId: string;
  name: string;
  heartbeatInterval: number;
  longitude: number;
  latitude: number;
  address: string;
  city: string;
  country: string;
  county: string;
  cityCode: string;
  zipcode: string;
  appLocationId: string;
  brand: string;
  model: string;
  variant: string;
  brandDBId: string;
  connectors: ConnectorProps[];
  chargingConfigurationProfileId: string | null;
  configuration: {
    AllowOfflineTxForUnknownId: boolean;
    AuthorizationCacheEnabled: boolean;
    AuthorizeRemoteTxRequests: boolean;
    BlinkRepeat: number;
    ClockAlignedDataInterval: number;
    ConnectionTimeOut: number;
    HeartbeatInterval: number;
    LightIntensity: boolean;
    LocalAuthorizeOffline: boolean;
    LocalPreAuthorize: boolean;
    MaxEnergyOnInvalidId: number;
    MeterValuesAlignedData: string;
    MeterValuesSampledData: string;
    MeterValueSampleInterval: number;
    MinimumStatusDuration: number;
    ResetRetries: number;
    ConnectorPhaseRotation: string;
    StopTransactionOnEVSideDisconnect: boolean;
    StopTransactionOnInvalidId: boolean;
    StopTxnAlignedData: string;
    StopTxnSampledData: string;
    TransactionMessageAttempts: number;
    TransactionMessageRetryInterval: number;
    UnlockConnectorOnEVSideDisconnect: boolean;
    WebSocketPingInterval: number;
  };
}

function myXor(...args: any[]) {
  return (
    args.filter((v) => !!v).length >= 1 &&
    args.filter((v) => !!v).length !== args.length
  );
}

const stepOneSchema = () =>
  Yup.object().shape({
    name: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    appLocationId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    registrationStatus: Yup.string()
      .min(1, 'Required')
      .required(i18n.t('Required')),
    chargePointId: Yup.string()
      .min(1, 'Required')
      .required(i18n.t('Required'))
      .matches(/^[a-zA-Z0-9-_]*$/),
    heartbeatInterval: Yup.number()
      .min(1, 'Required')
      .required(i18n.t('Required')),
  });

const stepTwoSchema = () =>
  Yup.object().shape({
    brand: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    model: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    variant: Yup.string().min(1, 'Required').required(i18n.t('Required')),
  });

const stepThreeSchema = () =>
  Yup.object().shape({
    connectors: Yup.array()
      .of(
        Yup.object().shape({
          socketType: Yup.string()
            .required(i18n.t('Required'))
            .min(1, 'Required'),
          maxPowerKW: Yup.number()
            .required(i18n.t('Required'))
            .min(1, 'Required'),
          level: Yup.string().required(i18n.t('Required')).min(1, 'Required'),
          rateId: Yup.string().required(i18n.t('Required')).min(0, 'Required'),
        })
      )
      .min(1, 'connectors must be at least 1'),
  });
const configurationValidationObject: { [key: string]: Yup.AnySchema } = {};
const fillConigrationSchema = () => {
  configurationData.forEach((x) => {
    if (x.required) {
      if (x.type === 'string') {
        configurationValidationObject[x.name] = Yup.string()
          .min(1, 'Required')
          .required(i18n.t('Required'));
      }
      if (x.type === 'int') {
        configurationValidationObject[x.name] = Yup.number()
          .min(0, 'Required')
          .required(i18n.t('Required'));
      }
    }
  });
};

fillConigrationSchema();
const stepExtraSchema = () =>
  Yup.object().shape({
    // eslint-disable-next-line react/forbid-prop-types
    configuration: Yup.object(configurationValidationObject),
  });
const addChargeStationFormSchema = () =>
  Yup.object().shape({
    applicationId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    appLocationId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    registrationStatus: Yup.string()
      .min(1, 'Required')
      .required(i18n.t('Required')),
    chargePointId: Yup.string()
      .min(1, 'Required')
      .required(i18n.t('Required'))
      .matches(
        /^[a-zA-Z0-9-_]*$/,
        i18n.t('AddChargeStations.englishCharactersOnly')
      ),
    heartbeatInterval: Yup.number()
      .min(1, 'Required')
      .required(i18n.t('Required')),
    longitude: Yup.number().test('longitude', 'Required', function (value) {
      const { latitude } = this.parent;
      // eslint-disable-next-line eqeqeq
      if (value == 0 || value == undefined) {
        return false;
      }
      // eslint-disable-next-line eqeqeq
      if (myXor(latitude, value) && value == 0) {
        return false;
      }
      return true;
    }),
    latitude: Yup.number().test('required', 'Required', function (value) {
      const { longitude } = this.parent;
      // eslint-disable-next-line eqeqeq
      if (value == 0 || value == undefined) {
        return false;
      }
      // eslint-disable-next-line eqeqeq
      if (myXor(longitude, value) && value == 0) {
        return false;
      }

      return true;
    }),
    address: Yup.string().test('required', 'Required', function (value) {
      const { city, country, zipcode, address } = this.parent;
      if (myXor(city, country, zipcode, address) && !value) {
        return false;
      }
      return true;
    }),
    city: Yup.string().test('required', 'Required', function (value) {
      const { city, country, zipcode, address } = this.parent;
      if (myXor(city, country, zipcode, address) && !value) {
        return false;
      }
      return true;
    }),
    country: Yup.string().test('required', 'Required', function (value) {
      const { city, country, zipcode, address } = this.parent;
      if (myXor(city, country, zipcode, address) && !value) {
        return false;
      }
      return true;
    }),
    county: Yup.string().required(i18n.t('Required')),
    cityCode: Yup.string()
      .max(2, i18n.t('AddChargeStations.max2Characters'))
      .required(i18n.t('Required')),
    zipcode: Yup.string().test('required', 'Required', function (value) {
      const { city, country, zipcode, address } = this.parent;
      if (myXor(city, country, zipcode, address) && !value) {
        return false;
      }
      return true;
    }),
    name: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    connectors: Yup.array()
      .of(
        Yup.object().shape({
          socketType: Yup.string()
            .required(i18n.t('Required'))
            .min(1, 'Required'),
          maxPowerKW: Yup.number()
            .required(i18n.t('Required'))
            .min(1, 'Required'),
          level: Yup.string().required(i18n.t('Required')).min(1, 'Required'),
          rateId: Yup.string().required(i18n.t('Required')).min(0, 'Required'),
        })
      )
      .min(1, 'connectors must be at least 1'),
    brand: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    model: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    variant: Yup.string().min(1, 'Required').required(i18n.t('Required')),

    configuration: Yup.lazy((value) => {
      if (Array.isArray(value)) {
        return Yup.array().nullable();
      }
      switch (typeof value) {
        case 'object':
          return Yup.object().shape(configurationValidationObject);

        default:
          return Yup.mixed();
      }
    }),

    // .when('chargingConfigurationProfileId', {
    //   is: true,
    //   then: Yup.object().shape(configurationValidationObject),
    // }),
  });
const validateStep = async (
  fields: string[],
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void
) => {
  await fields.map(async (x) => {
    await setFieldTouched(x, true, true);
    // await validateField(x);
    return null;
  });
  // eslint-disable-next-line no-promise-executor-return
};

const checkStepErrors = async (
  values: InitialValuesProps,
  stepSchema: Yup.AnyObjectSchema
) => {
  return stepSchema.isValid(values);
};

interface EditChargeStationProps {
  isOpen: boolean;
  toggleHandle: () => void;
}

const EditChargeStation = ({
  isOpen,
  toggleHandle,
}: EditChargeStationProps) => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const { selectedApplication } = useContext(ApplicationContext);
  const [levels, setLevels] = useState<SocketType[]>([]);
  const [sockets, setSockets] = useState<any>([]);
  const [maxPowerKW, setMaxPowerKW] = useState<any>([]);
  const [chooseFromMapModal, setChooseFromMapModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeTab, setactiveTab] = useState(1);
  const [maxTab, setmaxTab] = useState(4);
  const applicationContext = useContext(ApplicationContext);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [progressbarvalue, setprogressbarvalue] = useState(0);

  const [initialValues, setInitialValues] = useState<InitialValuesProps>({
    applicationId: selectedApplication?._id || '',
    registrationStatus: 'Accepted',
    chargePointId: '',
    name: '',
    heartbeatInterval: 2700,
    longitude: 0,
    latitude: 0,
    address: '',
    city: '',
    country: '',
    county: '',
    cityCode: '',
    zipcode: '',
    appLocationId: '',
    brand: '',
    model: '',
    variant: '',
    brandDBId: '',
    chargingConfigurationProfileId: null,
    configuration: {
      AllowOfflineTxForUnknownId: false,
      AuthorizationCacheEnabled: false,
      AuthorizeRemoteTxRequests: false,
      BlinkRepeat: 0,
      ClockAlignedDataInterval: 0,
      ConnectionTimeOut: 0,
      HeartbeatInterval: 0,
      LightIntensity: false,
      LocalAuthorizeOffline: false,
      LocalPreAuthorize: false,
      MaxEnergyOnInvalidId: 0,
      MeterValuesAlignedData: '',
      MeterValuesSampledData: '',
      MeterValueSampleInterval: 0,
      MinimumStatusDuration: 0,
      ResetRetries: 0,
      ConnectorPhaseRotation: '',
      StopTransactionOnEVSideDisconnect: false,
      StopTransactionOnInvalidId: false,
      StopTxnAlignedData: '',
      StopTxnSampledData: '',
      TransactionMessageAttempts: 0,
      TransactionMessageRetryInterval: 0,
      UnlockConnectorOnEVSideDisconnect: false,
      WebSocketPingInterval: 0,
    },
    connectors: [
      {
        level: '',
        socketType: '',
        maxPowerKW: '',
        rateId: '',
        isCabled: false,
        applyParking: false,
      },
    ],
  });
  const toggleTab = (tab: number, value: number) => {
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setprogressbarvalue(value);
  };
  useEffect(() => {
    context.LoadBrands();
    context.LoadApplications();
    context.LoadSocketTypes();
    context.LoadLocations({
      applicationId: applicationContext.selectedApplication?._id,
    });
    context.LoadChargingRates();
  }, []);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      applicationId: selectedApplication?._id || '',
    });
  }, [selectedApplication]);

  useEffect(() => {
    if (context.socketTypes && context.socketTypes.length > 0) {
      const newLevels = context.socketTypes;
      // eslint-disable-next-line
      let newSockets: any = {};
      // eslint-disable-next-line
      let maxPowerKW: any = {};
      newLevels.map((level: SocketType) => {
        newSockets[level.levelName] = level.sockets.reduce(
          (acc: any, cur) => [...acc, cur.name],
          []
        );
        level.sockets.map((socket: any) => {
          maxPowerKW[socket.name] = socket.powerRange;
          return null;
        });
        return null;
      });

      setLevels(newLevels);
      setSockets(newSockets);
      setMaxPowerKW(maxPowerKW);
    }
  }, [context.socketTypes]);

  useEffect(() => {
    if (
      context.applications &&
      context.applications.length > 0 &&
      context.locations &&
      context.locations.length > 0 &&
      context.chargingRates &&
      context.chargingRates.length > 0
    ) {
      setLoading(false);
    }
  }, [
    context.applications,
    context.socketTypes,
    context.locations,
    context.chargingRates,
  ]);

  const toggleChooseFromModal = () => {
    setChooseFromMapModal(!chooseFromMapModal);
  };

  return (
    <SlidingModal
      toggleHandle={() => {
        setInitialValues({
          applicationId: selectedApplication?._id || '',
          registrationStatus: 'Accepted',
          chargePointId: '',
          name: '',
          heartbeatInterval: 2700,
          longitude: 0,
          latitude: 0,
          address: '',
          city: '',
          country: '',
          county: '',
          cityCode: '',
          zipcode: '',
          appLocationId: '',
          brand: '',
          model: '',
          variant: '',
          brandDBId: '',
          chargingConfigurationProfileId: null,
          configuration: {
            AllowOfflineTxForUnknownId: false,
            AuthorizationCacheEnabled: false,
            AuthorizeRemoteTxRequests: false,
            BlinkRepeat: 0,
            ClockAlignedDataInterval: 0,
            ConnectionTimeOut: 0,
            HeartbeatInterval: 0,
            LightIntensity: false,
            LocalAuthorizeOffline: false,
            LocalPreAuthorize: false,
            MaxEnergyOnInvalidId: 0,
            MeterValuesAlignedData: '',
            MeterValuesSampledData: '',
            MeterValueSampleInterval: 0,
            MinimumStatusDuration: 0,
            ResetRetries: 0,
            ConnectorPhaseRotation: '',
            StopTransactionOnEVSideDisconnect: false,
            StopTransactionOnInvalidId: false,
            StopTxnAlignedData: '',
            StopTxnSampledData: '',
            TransactionMessageAttempts: 0,
            TransactionMessageRetryInterval: 0,
            UnlockConnectorOnEVSideDisconnect: false,
            WebSocketPingInterval: 0,
          },
          connectors: [
            {
              level: '',
              socketType: '',
              maxPowerKW: '',
              rateId: '',
              isCabled: false,
              applyParking: false,
            },
          ],
        });
        toggleTab(1, 0);
        toggleHandle();
      }}
      isOpen={isOpen}
      size='md'
      title={t('AddChargeStations.addChargeStation')}
    >
      {!loading ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values: InitialValuesProps, { resetForm }) => {
            const { configuration, ...otherValues } = values;
            const newStation: { [k: string]: any } = { ...otherValues };
            newStation.configurations = [];
            if (!otherValues.chargingConfigurationProfileId) {
              await Object.keys(configuration).forEach((key) => {
                newStation.configurations.push({
                  key,
                  value: configuration[key as keyof typeof configuration],
                });
              });
            } else {
              newStation.configurations = configuration;
            }
            return createChargeStation(newStation)
              .then((response) => {
                toast.success(
                  t('AddChargeStations.chargeStationCreatedSuccessfully')
                );
                resetForm();
                toggleTab(1, 0);
                return toggleHandle();
              })
              .catch((err) =>
                toast.error(t('AddChargeStations.unabletoCreateChargeStation'))
              );
          }}
          validationSchema={addChargeStationFormSchema}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            validateField,
            setFieldTouched,
            setErrors,
          }) => {
            useMemo(() => {
              Object.keys(errors).forEach((fieldName) => {
                setFieldTouched(fieldName);
              });
            }, [i18n.language]);
            return (
              <Form onSubmit={handleSubmit}>
                <div className='progress-nav mb-4'>
                  <Progress
                    value={progressbarvalue}
                    style={{ height: '1px' }}
                  />

                  <Nav
                    className='nav-pills progress-bar-tab custom-nav'
                    role='tablist'
                  >
                    <NavItem>
                      <NavLink
                        to='#'
                        id='pills-gen-info-tab'
                        className={classnames(
                          {
                            active: activeTab === 1,
                            done: activeTab <= 5 && activeTab >= 0,
                          },
                          'rounded-pill'
                        )}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        tag='button'
                      >
                        1
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to='#'
                        id='pills-gen-info-tab'
                        className={classnames(
                          {
                            active: activeTab === 2,
                            done: activeTab <= 5 && activeTab > 1,
                          },
                          'rounded-pill'
                        )}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        tag='button'
                      >
                        2
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to='#'
                        id='pills-gen-info-tab'
                        className={classnames(
                          {
                            active: activeTab === 3,
                            done: activeTab <= 5 && activeTab > 2,
                          },
                          'rounded-pill'
                        )}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        tag='button'
                      >
                        3
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to='#'
                        id='pills-gen-info-tab'
                        className={classnames(
                          {
                            active: activeTab === 4,
                            done: activeTab <= 5 && activeTab > 3,
                          },
                          'rounded-pill'
                        )}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        tag='button'
                      >
                        4
                      </NavLink>
                    </NavItem>
                    {maxTab === 5 && (
                      <NavItem>
                        <NavLink
                          to='#'
                          id='pills-gen-info-tab'
                          className={classnames(
                            {
                              active: activeTab === 5,
                              done: activeTab <= 5 && activeTab > 4,
                            },
                            'rounded-pill'
                          )}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          tag='button'
                        >
                          5
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={1}>
                    <Card>
                      <CardHeader className='text-center'>
                        <h4 className='card-title mb-0'>
                          {t('AddChargeStations.generalInformation')}
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <Field
                              component={renderField}
                              name='name'
                              label={t('AddChargeStations.name')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.name,
                                error: errors && errors.name && t(errors.name),
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              component={renderField}
                              name='chargePointId'
                              label={t('AddChargeStations.CP Id')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.chargePointId,
                                error:
                                  errors &&
                                  errors.chargePointId &&
                                  t(errors.chargePointId),
                              }}
                            />
                          </Col>
                        </Row>

                        {/* <Col>
                    <label htmlFor='select'>
                      {t('AddChargeStations.application')}
                    </label>
                    <Select
                      options={context.applications}
                      getOptionLabel={(x: ApplicationsType) => x.name}
                      getOptionValue={(x: ApplicationsType) => x._id}
                      placeholder={t('Select')}
                      isDisabled={selectedApplication}
                      value={context.applications.find(
                        (x: ApplicationsType) => x._id === values.applicationId
                      )}
                      onChange={(data: SingleValue<ApplicationsType>) => {
                        setFieldValue('applicationId', data?._id);
                        let newConnectorValues = values.connectors;
                        newConnectorValues = newConnectorValues.map((x) => ({
                          ...x,
                          rateId: '',
                        }));
                        setFieldValue('connectors', newConnectorValues);
                        setFieldValue('appLocationId', '');
                      }}
                    />
                    <ErrorMessage
                      name='applicationId'
                      component='div'
                      className='text-danger text-sm'
                    />
                  </Col> */}
                        <Row className='mb-3'>
                          <Col>
                            <label htmlFor='location'>
                              {t('AddChargeStations.chargingSite')}
                            </label>
                            <Select
                              value={context.locations.find(
                                (x: LocationType) =>
                                  x._id === values.appLocationId
                              )}
                              options={
                                context.locations.filter(
                                  (x: LocationType) =>
                                    x.application &&
                                    x.application._id === values.applicationId
                                ) || []
                              }
                              getOptionLabel={(x) => x.title}
                              getOptionValue={(x) => x._id}
                              placeholder={t('Select')}
                              onChange={(data) => {
                                setFieldValue('appLocationId', data._id);
                              }}
                              onFocus={() => {
                                setFieldTouched('appLocationId', true, true);
                              }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderColor:
                                    touched.appLocationId &&
                                    errors.appLocationId
                                      ? '#f06548'
                                      : provided.borderColor,
                                }),
                              }}
                              // theme={theme => ({
                              //   ...theme,
                              //   color
                              // })}
                            />
                            {/* {touched.appLocationId && errors?.appLocationId && (
                              <div className='field-error text-danger '>
                                {errors.appLocationId}
                              </div>
                            )} */}
                            <ErrorMessage
                              name='appLocationId'
                              component='div'
                              className='field-error text-danger text-sm'
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              component={renderSelectField}
                              name='registrationStatus'
                              label={t('AddChargeStations.registrationStatus*')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.registrationStatus,
                                error:
                                  errors &&
                                  errors.registrationStatus &&
                                  t(errors.registrationStatus),
                              }}
                            >
                              <option value='Accepted'>
                                {t('AddChargeStations.accepted')}
                              </option>
                              <option value='Pending'>
                                {t('AddChargeStations.pending')}
                              </option>
                              <option value='Rejected'>
                                {t('AddChargeStations.rejected')}
                              </option>
                            </Field>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              component={renderField}
                              name='heartbeatInterval'
                              label={t('AddChargeStations.heartbeatInterval')}
                              className='form-control'
                              type='number'
                              meta={{
                                touched: touched.heartbeatInterval,
                                error:
                                  errors &&
                                  errors.heartbeatInterval &&
                                  t(errors.heartbeatInterval),
                              }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Row>
                      <Col className='d-flex align-items-start gap-3 '>
                        <button
                          type='button'
                          className='btn btn-link btn-label right ms-auto nexttab nexttab'
                          onClick={() => {
                            const validatingFields = [
                              'name',
                              'chargePointId',
                              'registrationStatus',
                              'heartbeatInterval',
                              'appLocationId',
                            ];
                            validateStep(validatingFields, setFieldTouched)
                              .then(() =>
                                checkStepErrors(values, stepOneSchema())
                              )
                              .then((valid) => {
                                if (valid)
                                  toggleTab(
                                    activeTab + 1,
                                    (activeTab * 100) / (maxTab - 1)
                                  );
                              });
                          }}
                        >
                          <i className='ri-arrow-right-line label-icon align-middle fs-16 ms-4' />
                          {t('AddChargeStations.next')}
                        </button>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Card>
                      <CardHeader className='text-center'>
                        <h4 className='card-title mb-0'>
                          {t('AddChargeStations.chargeStationDetails')}
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <Row className='mb-3'>
                          <Col>
                            <label htmlFor='location'>
                              {t('AddChargeStations.brand')}
                            </label>
                            <Select
                              value={context?.brands?.find(
                                (x: { label: string; value: string }) =>
                                  x.value === values.brand
                              )}
                              options={context.brands || []}
                              placeholder={t('Select')}
                              onChange={(data) => {
                                context.LoadModels(data.value);
                                setFieldValue('model', '');
                                setFieldValue('brand', data.value);
                              }}
                              onFocus={() => {
                                setFieldTouched('brand', true, true);
                              }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderColor:
                                    touched.brand && errors.brand
                                      ? '#f06548'
                                      : provided.borderColor,
                                }),
                              }}
                            />
                            <ErrorMessage
                              name='brand'
                              component='div'
                              className='text-danger text-sm'
                            />
                          </Col>
                        </Row>
                        <Row className='mb-3'>
                          <Col>
                            <label htmlFor='location'>
                              {t('AddChargeStations.model')}
                            </label>
                            <Select
                              value={
                                context?.models?.find(
                                  (x: { label: string; value: string }) =>
                                    x.value === values.model
                                ) || { label: t('Select'), value: '' }
                              }
                              options={context?.models || []}
                              isDisabled={!values.brand}
                              placeholder={t('Select')}
                              onChange={(data) => {
                                context.LoadVariants(values.brand, data.value);
                                setFieldValue('variant', '');
                                setFieldValue('model', data.value);
                              }}
                              onFocus={() => {
                                setFieldTouched('model', true, true);
                              }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderColor:
                                    touched.model && errors.model
                                      ? '#f06548'
                                      : provided.borderColor,
                                }),
                              }}
                            />
                            <ErrorMessage
                              name='model'
                              component='div'
                              className='text-danger text-sm'
                            />
                          </Col>
                        </Row>
                        <Row className='mb-3'>
                          <Col>
                            <label htmlFor='location'>
                              {t('AddChargeStations.variant')}
                            </label>
                            <Select
                              value={
                                context?.variants?.find(
                                  (x: { label: string; value: string }) =>
                                    x.value === values.variant
                                ) || { label: t('Select'), value: '' }
                              }
                              options={context?.variants || []}
                              isDisabled={!values.model}
                              placeholder={t('Select')}
                              onChange={(data) => {
                                setFieldValue('variant', data.value);
                              }}
                              onFocus={() => {
                                setFieldTouched('variant', true, true);
                              }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderColor:
                                    touched.variant && errors.variant
                                      ? '#f06548'
                                      : provided.borderColor,
                                }),
                              }}
                            />
                            <ErrorMessage
                              name='variant'
                              component='div'
                              className='text-danger text-sm'
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Row>
                      <Col className='d-flex align-items-start gap-3 mt-4'>
                        <button
                          type='button'
                          className='btn btn-link text-decoration-none btn-label previestab'
                          onClick={() => {
                            toggleTab(
                              activeTab - 1,
                              ((activeTab - 2) * 100) / (maxTab - 1)
                            );
                          }}
                        >
                          <i className='ri-arrow-left-line label-icon align-middle fs-16 me-2' />{' '}
                          {t('AddChargeStations.backToGeneralInformation')}
                        </button>
                        <button
                          type='button'
                          className='btn btn-link btn-label right ms-auto nexttab nexttab'
                          onClick={() => {
                            const validatingFields = [
                              'brand',
                              'model',
                              'variant',
                            ];
                            validateStep(validatingFields, setFieldTouched)
                              .then(() =>
                                checkStepErrors(values, stepTwoSchema())
                              )
                              .then((valid) => {
                                if (valid) {
                                  getChargePointConnectors(
                                    values.brand,
                                    values.model,
                                    values.variant
                                  )
                                    .then((res) => {
                                      const connectors =
                                        res?.data?.chargePoints[0]?.connectors.map(
                                          (x: object) => ({
                                            ...x,
                                            applyParking: false,
                                          })
                                        );
                                      setFieldValue('connectors', connectors);
                                      setFieldValue(
                                        'brandDBId',
                                        res?.data?.chargePoints[0]?._id
                                      );
                                      return getConfigurations(
                                        values.applicationId,
                                        res.data.chargePoints[0]._id
                                      );
                                    })
                                    .then((res) => {
                                      if (
                                        res.data
                                          .chargingConfigurationProfileId ===
                                        null
                                      ) {
                                        setmaxTab(5);
                                        setFieldValue('configuration', {
                                          AllowOfflineTxForUnknownId: false,
                                          AuthorizationCacheEnabled: false,
                                          AuthorizeRemoteTxRequests: false,
                                          BlinkRepeat: 0,
                                          ClockAlignedDataInterval: 0,
                                          ConnectionTimeOut: 0,
                                          HeartbeatInterval: 0,
                                          LightIntensity: false,
                                          LocalAuthorizeOffline: false,
                                          LocalPreAuthorize: false,
                                          MaxEnergyOnInvalidId: 0,
                                          MeterValuesAlignedData: '',
                                          MeterValuesSampledData: '',
                                          MeterValueSampleInterval: 0,
                                          MinimumStatusDuration: 0,
                                          ResetRetries: 0,
                                          ConnectorPhaseRotation: '',
                                          StopTransactionOnEVSideDisconnect:
                                            false,
                                          StopTransactionOnInvalidId: false,
                                          StopTxnAlignedData: '',
                                          StopTxnSampledData: '',
                                          TransactionMessageAttempts: 0,
                                          TransactionMessageRetryInterval: 0,
                                          UnlockConnectorOnEVSideDisconnect:
                                            false,
                                          WebSocketPingInterval: 0,
                                        });
                                        toggleTab(activeTab + 1, 50);
                                      } else {
                                        setFieldValue(
                                          'chargingConfigurationProfileId',
                                          res.data
                                            .chargingConfigurationProfileId
                                        );
                                        setFieldValue(
                                          'configuration',
                                          res.data.chargingConfigurations
                                        );
                                        setmaxTab(4);
                                        toggleTab(
                                          activeTab + 1,
                                          (activeTab * 100) / (maxTab - 1)
                                        );
                                      }
                                    })
                                    .catch((e) => {
                                      toast.error('Error');
                                      console.log(e.message);
                                    });
                                }
                              });
                          }}
                        >
                          <i className='ri-arrow-right-line label-icon align-middle fs-16 ms-2' />
                          {t('AddChargeStations.next')}
                        </button>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={3}>
                    <Card className='m-2'>
                      <CardHeader className='text-center'>
                        <h4 className='card-title mb-0'>
                          {t('AddChargeStations.connectors')}
                        </h4>
                      </CardHeader>
                      <CardBody className='p-0'>
                        <FieldArray
                          name='connectors'
                          render={(arrayHelpers) => {
                            return (
                              values.connectors &&
                              values.connectors.map((outlet, index: number) => (
                                <div key={index.toString()}>
                                  <h6 className='mt-2 ms-2'>
                                    Connector {index + 1}
                                  </h6>
                                  <Row>
                                    <Col>
                                      <ul className='list-group list-group-flush'>
                                        <CardInfoText
                                          title={t('AddChargeStations.level')}
                                          value={values.connectors[index].level}
                                        />
                                        <CardInfoText
                                          title={t('AddChargeStations.socket')}
                                          value={
                                            values.connectors[index].socketType
                                          }
                                        />
                                        <CardInfoText
                                          title={t(
                                            'AddChargeStations.maxPowerKW'
                                          )}
                                          value={
                                            values.connectors[index].maxPowerKW
                                          }
                                        />
                                        <CardInfoText
                                          title={t(
                                            'AddChargeStations.isCabled'
                                          )}
                                          value={
                                            values.connectors[index].isCabled
                                              ? t('AddChargeStations.yes')
                                              : t('AddChargeStations.no')
                                          }
                                        />
                                      </ul>
                                    </Col>
                                  </Row>
                                  <Row key={index.toString()} className='mx-2'>
                                    <Col md={12} sm={12}>
                                      <Col>
                                        <FormGroup>
                                          <label htmlFor='chargingRate'>
                                            {t(
                                              'AddChargeStations.chargingRate'
                                            )}{' '}
                                          </label>
                                          <Field
                                            name={`connectors.${index}.rateId`}
                                            className={`form-control  ${
                                              touched &&
                                              touched.connectors &&
                                              touched?.connectors[index] &&
                                              touched?.connectors[index]
                                                .rateId &&
                                              errors &&
                                              errors.connectors &&
                                              getIn(
                                                errors,
                                                `connectors[${index}].rateId`
                                              )
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            as='select'
                                            onChange={handleChange}
                                          >
                                            <option value=''>
                                              {t(
                                                'AddChargeStations.chooseChargingRate'
                                              )}{' '}
                                            </option>

                                            {context.chargingRates &&
                                              context.applications &&
                                              values.applicationId &&
                                              context.chargingRates.length >
                                                0 &&
                                              context.chargingRates
                                                .filter(
                                                  (x: ChargingRatesType) =>
                                                    x?.application?._id ===
                                                    values?.applicationId
                                                )
                                                .map(
                                                  (rate: ChargingRatesType) => (
                                                    <option
                                                      key={rate?._id}
                                                      value={rate?._id}
                                                    >
                                                      {rate?.title}
                                                    </option>
                                                  )
                                                )}
                                          </Field>{' '}
                                          <ErrorMessage
                                            name={`connectors.${index}.rateId`}
                                            component='div'
                                            className='field-error text-danger'
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Col>
                                  </Row>
                                  <Row className='mx-2'>
                                    <Col className='my-auto'>
                                      <FormGroup className='p-0'>
                                        <Label
                                          className='form-check-label me-2'
                                          for={`connectors.${index}.applyParking`}
                                        >
                                          {t('AddChargeStations.applyParking')}:
                                        </Label>
                                        <Input
                                          name={`connectors.${index}.applyParking`}
                                          className='form-check-input pt-1'
                                          type='checkbox'
                                          color='primary'
                                          checked={
                                            values.connectors[index]
                                              .applyParking
                                          }
                                          label={t(
                                            'AddChargeStations.applyParking'
                                          )}
                                          id={`connectors.${index}.applyParking`}
                                          onChange={(e: any) => {
                                            setFieldValue(
                                              `connectors.${index}.applyParking`,
                                              e.target.checked
                                            );
                                          }}
                                        />{' '}
                                        <Label
                                          className='form-check-label me-2'
                                          for={`connectors.${index}.applyParking`}
                                        >
                                          {t('AddChargeStations.yes')}
                                        </Label>
                                        <Input
                                          name={`connectors.${index}.applyParking`}
                                          className='form-check-input pt-1'
                                          type='checkbox'
                                          color='primary'
                                          label={t(
                                            'AddChargeStations.applyParking'
                                          )}
                                          checked={
                                            !values.connectors[index]
                                              .applyParking
                                          }
                                          id={`connectors.${index}.applyParking`}
                                          onChange={(e: any) => {
                                            setFieldValue(
                                              `connectors.${index}.applyParking`,
                                              !e.target.checked
                                            );
                                          }}
                                        />{' '}
                                        <Label
                                          className='form-check-label me-2'
                                          for={`connectors.${index}.applyParking`}
                                        >
                                          {t('AddChargeStations.no')}
                                        </Label>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>
                              ))
                            );
                          }}
                        />
                      </CardBody>
                    </Card>
                    <Row>
                      <Col className='d-flex align-items-start gap-3 mt-4'>
                        <button
                          type='button'
                          className='btn btn-link text-decoration-none btn-label previestab'
                          onClick={() => {
                            toggleTab(
                              activeTab - 1,
                              ((activeTab - 2) * 100) / (maxTab - 1)
                            );
                          }}
                        >
                          <i className='ri-arrow-left-line label-icon align-middle fs-16 me-2' />{' '}
                          {t('AddChargeStations.backToChargeStationsDetails')}
                        </button>
                        <button
                          type='button'
                          className='btn btn-link btn-label right ms-auto nexttab nexttab'
                          onClick={() => {
                            const validatingFields = values.connectors.map(
                              (x: any, i: number) => `connectors.${i}.rateId`
                            );
                            validateStep(validatingFields, setFieldTouched)
                              .then(() =>
                                checkStepErrors(values, stepThreeSchema())
                              )
                              .then((valid) => {
                                if (valid) {
                                  toggleTab(
                                    activeTab + 1,
                                    (activeTab * 100) / (maxTab - 1)
                                  );
                                }
                              });
                          }}
                        >
                          <i className='ri-arrow-right-line label-icon align-middle fs-16 ms-2' />
                          {t('AddChargeStations.next')}
                        </button>
                      </Col>
                    </Row>
                  </TabPane>
                  {maxTab === 5 && (
                    <TabPane tabId={4}>
                      <Card>
                        <CardHeader className='text-center'>
                          <h4 className='card-title mb-0'>
                            {t('AddChargeStations.configuration')}
                          </h4>
                        </CardHeader>
                        <CardBody>
                          {configurationData.map((x, index: number) => {
                            if (x.type === 'string') {
                              return (
                                <Row key={index.toString()}>
                                  <Col>
                                    <Field
                                      component={renderField}
                                      name={`configuration.${x.name}`}
                                      label={`${x.name}`}
                                      className='form-control'
                                      meta={{
                                        touched:
                                          touched?.configuration &&
                                          touched?.configuration[
                                            x.name as keyof typeof touched.configuration
                                          ],
                                        error:
                                          errors &&
                                          errors?.configuration &&
                                          t(
                                            errors?.configuration[
                                              x.name as keyof typeof touched.configuration
                                            ]
                                          ),
                                      }}
                                    />
                                  </Col>
                                </Row>
                              );
                            }
                            if (x.type === 'int') {
                              return (
                                <Row>
                                  <Col>
                                    <Field
                                      component={renderField}
                                      name={`configuration.${x.name}`}
                                      label={`${x.name}`}
                                      className='form-control'
                                      type='number'
                                      meta={{
                                        touched:
                                          touched?.configuration &&
                                          touched?.configuration[
                                            x.name as keyof typeof touched.configuration
                                          ],
                                        error:
                                          errors &&
                                          errors?.configuration &&
                                          t(
                                            errors?.configuration[
                                              x.name as keyof typeof touched.configuration
                                            ]
                                          ),
                                      }}
                                    />
                                  </Col>
                                </Row>
                              );
                            }
                            return (
                              <Row>
                                <Col>
                                  <h6 className='form-check-label mb-2'>
                                    {x.name}
                                  </h6>
                                  <FormGroup className='p-0'>
                                    <Input
                                      name={`configuration.${x.name}`}
                                      className='form-check-input pt-1'
                                      type='checkbox'
                                      color='primary'
                                      checked={
                                        !!values.configuration[
                                          x.name as keyof typeof values.configuration
                                        ]
                                      }
                                      label={t(
                                        'AddChargeStations.applyParking'
                                      )}
                                      id={`configuration.${x.name}`}
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          `configuration.${x.name}`,
                                          e.target.checked
                                        );
                                      }}
                                    />{' '}
                                    <Label
                                      className='form-check-label me-2'
                                      for={`configuration.${x.name}`}
                                    >
                                      {t('AddChargeStations.yes')}
                                    </Label>
                                    <Input
                                      name={`configuration.${x.name}`}
                                      className='form-check-input pt-1'
                                      type='checkbox'
                                      color='primary'
                                      checked={
                                        !values.configuration[
                                          x.name as keyof typeof values.configuration
                                        ]
                                      }
                                      id={`configuration.${x.name}`}
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          `configuration.${x.name}`,
                                          e.target.checked
                                        );
                                      }}
                                    />{' '}
                                    <Label
                                      className='form-check-label me-2'
                                      for={`configuration.${x.name}`}
                                    >
                                      {t('AddChargeStations.no')}
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                            );
                          })}
                        </CardBody>
                      </Card>
                      <Row>
                        <Col className='d-flex align-items-start gap-3 mt-4'>
                          <button
                            type='button'
                            className='btn btn-link text-decoration-none btn-label previestab'
                            onClick={() => {
                              toggleTab(
                                activeTab - 1,
                                ((activeTab - 2) * 100) / (maxTab - 1)
                              );
                            }}
                          >
                            <i className='ri-arrow-left-line label-icon align-middle fs-16 me-2' />{' '}
                            {t('AddChargeStations.backToConnectors')}
                          </button>
                          <button
                            type='button'
                            className='btn btn-link btn-label right ms-auto nexttab nexttab'
                            onClick={() => {
                              const validatingFields = Object.keys(
                                values.configuration
                              ).map((x: string) => `configuration.${x}`);
                              validateStep(validatingFields, setFieldTouched)
                                .then(() =>
                                  checkStepErrors(values, stepExtraSchema())
                                )
                                .then((valid) => {
                                  if (valid) {
                                    toggleTab(
                                      activeTab + 1,
                                      (activeTab * 100) / (maxTab - 1)
                                    );
                                  }
                                });
                            }}
                          >
                            <i className='ri-arrow-right-line label-icon align-middle fs-16 ms-2' />
                            {t('AddChargeStations.next')}
                          </button>
                        </Col>
                      </Row>
                    </TabPane>
                  )}
                  <TabPane tabId={maxTab}>
                    <Card>
                      <CardHeader className='text-center'>
                        <h4 className='card-title mb-0'>
                          {t('AddChargeStations.location')}
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <Row className='mb-3'>
                          <Col xl={12}>
                            <Button
                              onClick={toggleChooseFromModal}
                              color='secondary'
                              type='button'
                              disabled={isSubmitting}
                              className='me-1 mt-4'
                            >
                              {t('AddChargeStations.chooseFromMap')}
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12}>
                            <Field
                              component={renderField}
                              name='latitude'
                              label={t('AddChargeStations.latitude')}
                              className='form-control'
                              type='number'
                              meta={{
                                touched: touched.latitude,
                                error:
                                  errors &&
                                  errors.latitude &&
                                  t(errors.latitude),
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={12}>
                            <Field
                              component={renderField}
                              name='longitude'
                              label={t('AddChargeStations.longitude')}
                              className='form-control'
                              type='number'
                              meta={{
                                touched: touched.longitude,
                                error:
                                  errors &&
                                  errors.longitude &&
                                  t(errors.longitude),
                              }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Field
                              component={renderField}
                              name='country'
                              label={t('AddChargeStations.country')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.country,
                                error:
                                  errors && errors.country && t(errors.country),
                              }}
                            />
                          </Col>{' '}
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              component={renderField}
                              name='city'
                              label={t('AddChargeStations.city')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.city,
                                error: errors && errors.city && t(errors.city),
                              }}
                            />
                          </Col>{' '}
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              component={renderField}
                              name='county'
                              label={t('AddChargeStations.county')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.county,
                                error:
                                  errors && errors.county && t(errors.county),
                              }}
                            />
                          </Col>{' '}
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              component={renderField}
                              name='cityCode'
                              label={t('AddChargeStations.cityCode')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.cityCode,
                                error:
                                  errors &&
                                  errors.cityCode &&
                                  t(errors.cityCode),
                              }}
                            />
                          </Col>{' '}
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              component={renderField}
                              name='zipcode'
                              label={t('AddChargeStations.zipcode')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.zipcode,
                                error:
                                  errors && errors.zipcode && t(errors.zipcode),
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              component={renderTextArea}
                              name='address'
                              label={t('AddChargeStations.address')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.address,
                                error:
                                  errors && errors.address && t(errors.address),
                              }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Row className='mt-3'>
                      <Col className='d-flex align-items-start gap-3 mt-4'>
                        <button
                          type='button'
                          className='btn btn-link text-decoration-none btn-label previestab'
                          onClick={() => {
                            toggleTab(activeTab - 1, 50);
                          }}
                        >
                          <i className='ri-arrow-left-line label-icon align-middle fs-16 me-2' />{' '}
                          {t('AddChargeStations.backToConfigurations')}
                        </button>

                        <Button
                          color='primary'
                          type='submit'
                          className='right ms-auto nexttab nexttab'
                          disabled={isSubmitting}
                        >
                          {t('AddChargeStations.submit')}
                        </Button>
                      </Col>
                    </Row>

                    {chooseFromMapModal && (
                      <ChooseFromMap
                        toggleModal={toggleChooseFromModal}
                        isOpen={chooseFromMapModal}
                        changeLatLong={(lat: number, long: number) => {
                          setFieldValue('latitude', lat);
                          setFieldValue('longitude', long);
                        }}
                        changeAddress={(
                          address: string,
                          city: string,
                          country: string,
                          zipcode: string,
                          county: string,
                          cityCode: string
                        ) => {
                          setFieldValue('address', address);
                          setFieldValue('city', city);
                          setFieldValue('country', country);
                          setFieldValue('zipcode', zipcode);
                          setFieldValue('county', county);
                          setFieldValue('cityCode', cityCode);
                        }}
                        lat={values.latitude}
                        long={values.longitude}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </SlidingModal>
  );
};

export default EditChargeStation;
