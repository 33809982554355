import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Row,
  Col,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import Container from 'Components/Common/Container';
import SearchInput from 'Components/Common/SearchInput';
import SelectBox from 'Components/Common/SelectBox';
import * as Icons from 'react-feather';
import { editDriver, deleteDriver, getDrivers } from 'actions/drivers';
import { ApplicationsType, DriverType } from 'declerations/DefaultTypes';
import EditContext from 'Components/Contexts/EditContext';
import { Edit3, Plus } from 'react-feather';
import AutoRefresh from 'Components/Common/AutoRefresh';
import ExportBlock from 'laxyExcel/ExportComponents';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import AddDriver from './AddDriver/AddDriver';
import DriverDetail from './DriverDetail/DriverDetail';

const Drivers = () => {
  const MySwal = withReactContent(Swal);

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [filterOpened, setfilterOpened] = useState('0');
  const [loading, setLoading] = useState<boolean>(true);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const [isOpenEdit, setisOpenEdit] = useState(false);
  const [filtered, setFiltered] = useState('');
  const [data, setData] = useState<DriverType[] | undefined>();
  const [count, setCount] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(false);
  const [PerRows, setPerRows] = useState<number>(1000);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [filters, setFilters] = useState<{
    isOtpVerified: string | undefined;
    defaultCard: string | undefined;
    applicationId: string | undefined;
    consumerType: string | undefined;
    limit: number | undefined;
    offset: number | undefined;
  }>({
    isOtpVerified: 'null',
    defaultCard: 'null',
    applicationId: 'null',
    consumerType: 'null',
    limit: PerRows,
    offset: 0,
  });
  const context = useContext(DataContext);
  const editContext = useContext(EditContext);
  const headers = [
    'fname',
    'lname',
    'email',
    'ocppTagId',
    'phone',
    'source',
    'application.name',
  ].map((key) => ({
    key,
    value: `drivers.${key}`,
    // mapper: mapper != null ? mapper[key] : '',
  }));

  const loadDataTable = () => {
    getDrivers(filters)
      .then((res: any) => {
        setData(res?.data?.consumers);
        setCount(res?.data?.count);
      })
      .then(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadDataTable();
  }, [filters]);

  const pagination = (e: number) => {
    setPageNumber(e);
    const control = count / PerRows;
    const flag = Math.ceil(control);
    if (e <= flag) {
      setFilters({
        ...filters,
        offset: PerRows && (e - 1) * PerRows,
        limit: PerRows,
      });
    } else if (e === flag) {
      setFilters({
        ...filters,
        offset: count,
        limit: PerRows,
      });
    }
  };

  const handlePerRowsChange = (e: number) => {
    setPerRows(e);
    setReset(!reset);
    setFilters({
      ...filters,
      offset:
        PerRows && pageNumber === 0 ? pageNumber * e : (pageNumber - 1) * e,
      limit: e,
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);
    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh, filters]);

  const changeFilter = (filterType: string, value: string) => {
    switch (filterType) {
      case 'isOtpVerified':
        setFilters({
          ...filters,
          isOtpVerified: value,
        });

        break;
      case 'defaultCard':
        setFilters({
          ...filters,
          defaultCard: value,
        });

        break;
      case 'applicationId':
        setFilters({
          ...filters,
          applicationId: value,
        });
        break;
      case 'consumerType':
        setFilters({
          ...filters,
          consumerType: value,
        });
        break;
      default:
        break;
    }
  };
  const search = (rowData: DriverType[] | undefined) => {
    if (!rowData) {
      return [];
    }
    return rowData.filter(
      (row: DriverType) =>
        row?.fname?.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
        row?.lname?.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
        row?.email?.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
        row?.ocppTagId?.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
        row?.phone?.toLowerCase().indexOf(filtered.toLowerCase()) > -1
    );
  };

  return (
    <Container
      title={t('Drivers')}
      rightSide={
        <AutoRefresh
          isActive={isActiveAutoRefresh}
          onPress={() => {
            setIsActiveAutoRefresh(!isActiveAutoRefresh);
          }}
        />
      }
    >
      <Row className='mb-2 d-flex justify-content-end'>
        <Col xl={3} sm={12}>
          {' '}
        </Col>
        <Col xl={6} sm={12}>
          {' '}
        </Col>
        <Col
          xl={3}
          sm={12}
          className='d-flex justify-content-end '
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <ExportBlock data={data} header={headers} name={t('Drivers')} />
          <Button
            className='ms-1'
            aria-hidden='true'
            color='primary'
            onClick={() => {
              setisOpenEdit(false);
              setAddIsOpen(!addIsOpen);
            }}
          >
            <Plus className='me-2 p-0' size='20' />

            {t('Add')}
          </Button>
        </Col>
      </Row>
      <Row className='mb-1'>
        <Col>
          <Accordion open={filterOpened}>
            <AccordionItem>
              <AccordionHeader
                targetId='1'
                onClick={() =>
                  setfilterOpened(filterOpened === '1' ? '0' : '1')
                }
              >
                <Row className='w-100 py-0'>
                  <Col xl={4} sm={12} xs={12} className='d-flex  pb-0'>
                    <SearchInput
                      isFilter
                      onFocus={() => setfilterOpened('1')}
                      onChange={(text) => {
                        setFiltered(text);
                      }}
                    />
                  </Col>
                </Row>
              </AccordionHeader>
              <AccordionBody accordionId='1'>
                <Row className='my-4'>
                  <Col xl={2} sm={12}>
                    <SelectBox
                      label={t('Driver.isOtpVerified')}
                      value={filters.isOtpVerified}
                      onChange={(value) => changeFilter('isOtpVerified', value)}
                    >
                      <option value='null'>{t('all')}</option>
                      <option value='1'>{t('yes')}</option>
                      <option value='0'>{t('no')}</option>
                    </SelectBox>
                  </Col>
                  <Col xl={2} sm={12}>
                    <SelectBox
                      label={t('Driver.defaultCard')}
                      value={filters.defaultCard}
                      onChange={(value) => changeFilter('defaultCard', value)}
                    >
                      <option value='null'>{t('all')}</option>
                      <option value='1'>{t('yes')}</option>
                      <option value='0'>{t('no')}</option>
                    </SelectBox>
                  </Col>
                  <Col xl={2} sm={12}>
                    <SelectBox
                      value={filters.applicationId}
                      label={t('Driver.applications')}
                      onChange={(value) => changeFilter('applicationId', value)}
                    >
                      <option value='null'>{t('all')}</option>
                      {context.applications &&
                        context.applications.length > 0 &&
                        context.applications.map((item: ApplicationsType) => (
                          <option value={item._id}>{item.name}</option>
                        ))}
                    </SelectBox>
                  </Col>
                  <Col xl={2} sm={12}>
                    <SelectBox
                      value={filters.consumerType}
                      label={t('DriverAdd.consumerType')}
                      onChange={(value) => changeFilter('consumerType', value)}
                    >
                      <option value='null'>{t('all')}</option>
                      <option value='PERSON'>{t('DriverAdd.personal')}</option>
                      <option value='SERVICE'>{t('DriverAdd.service')}</option>
                    </SelectBox>
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Col>
      </Row>
      {/* <BreadCrumb title='DRIVERS ' pageTitle='CRM' /> */}
      <div>
        <DataTable
          loading={loading}
          noDataText={t('Driver.thereArenoDriverCreated')}
          paginationServer
          paginationTotalRows={count}
          paginationPerPage={PerRows}
          onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
          onChangePage={(e) => pagination(e)}
          paginationResetDefaultPage={reset}
          pointerOnHover
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 1000]}
          onRowClicked={(row) => {
            setIsOpen(true);
            editContext.SetEditDriver(row);
          }}
          columns={[
            {
              name: t('Driver.firstName'),
              selector: (row: DriverType) => row.fname,
              sortable: true,
            },
            {
              name: t('Driver.lastName'),
              selector: (row: DriverType) => row.lname,
            },
            // {
            //   name: t('Driver.email'),
            //   selector: (row: DriverType) => row.email,
            // },
            {
              name: t('Driver.ocppgTagId'),
              selector: (row: DriverType) => row.ocppTagId,
              sortable: true,
            },
            {
              name: t('Driver.phone'),
              selector: (row: DriverType) => row.phone,
              sortable: true,
            },
            {
              name: t('Driver.source'),
              selector: (row: DriverType) => row.source,
              sortable: true,
            },
            {
              name: t('Driver.enabled'),

              cell: (props: DriverType) => {
                return (
                  <div className='m-atuo align-middle d-flex'>
                    <Toggle
                      icons={false}
                      defaultChecked={!props.isBlocked}
                      onChange={(e) => {
                        return editDriver(props._id, {
                          isBlocked: !e.target.checked,
                        })
                          .then((response) => {
                            loadDataTable();
                            toast.success(t('Driver.driverEditedSuccessfully'));
                          })
                          .catch((err) =>
                            toast.error(t('Driver.unabletoEditDriver'))
                          );
                      }}
                      className='switch-danger'
                    />
                  </div>
                );
              },
            },
            {
              name: t('Driver.actions'),
              cell: (row: DriverType) => {
                return (
                  <div>
                    {/* <i
                      className='mr-1 mdi mdi-eye pointer'
                      aria-hidden
                      onClick={() => {
                        setIsOpen(true);
                        editContext.SetEditDriver(row);
                        // this.props.history.push(`/drivers/${props._id}`, {
                        //   driver: props,
                        // });
                      }}
                    /> */}
                    <Edit3
                      className='mr-1'
                      cursor='pointer'
                      onClick={() => {
                        editContext.SetEditDriver(row);
                        setisOpenEdit(true);
                        setAddIsOpen(true);
                      }}
                    />

                    <Icons.Trash2
                      color='#f00'
                      className='ms-1'
                      cursor='pointer'
                      onClick={(e) => {
                        MySwal.fire({
                          title: t('Driver.doYouWanttodeleteThisDriver'),
                          showConfirmButton: true,
                          showCancelButton: true,
                          cancelButtonText: t('no'),
                          confirmButtonText: t('yes'),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteDriver(row._id).then((res) => {
                              if (res.data.deleted === true) {
                                loadDataTable();
                                toast.success(t('Driver.succesfully'));
                              } else toast.error(t('Driver.fail'));
                            });
                          }
                        });
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          data={search(data) || []}
        />
      </div>
      <DriverDetail
        isOpen={isOpen}
        toggleHandle={() => {
          loadDataTable();
          setIsOpen(false);
        }}
      />
      <AddDriver
        isOpen={addIsOpen}
        isEditing={isOpenEdit}
        toggleHandle={() => {
          setAddIsOpen(false);
          setisOpenEdit(false);
          loadDataTable();
        }}
      />
    </Container>
  );
};
export default Drivers;
