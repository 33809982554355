import React, { useEffect, useState } from 'react';
import { Col, Row, Card, CardBody, FormGroup } from 'reactstrap';
import CardWidget from 'Components/Common/CardWidget';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';
import getSocketSvg from 'helpers/Socket';
import { getStatisticsCount } from 'actions/chargeStations';
import { getApplications } from 'actions/applications';
import * as Icon from 'react-feather';
import { getLabelCurrency } from 'helpers/Currencies';

const StatsCards = ({
  startDate,
  endDate,
  applicationId,
  locationId,
  chargePointId,
  user,
  ocppTagId,
}: {
  startDate: string | undefined;
  endDate: string | undefined;
  applicationId: string | undefined;
  locationId: string | undefined;
  chargePointId: string | undefined;
  user: string | undefined;
  ocppTagId: string | undefined;
}) => {
  const { t } = useTranslation();

  const [state, setState] = useState<
    | {
        transactions: number;
        durationInHrs: number;
        evseSuspendedInHrs: number;
        powerConsumptionInKWh: number;
        endorsements: [
          {
            currency: string;
            earnings: number;
            cpoMargin: number;
            dealerMargin: number;
            revenue: number;
          }
        ];
        drivers: number;
        newUsers: number;
        chargeStations: number;
        acChargeStations: number;
        dcChargeStations: number;
      }
    | undefined
  >();
  useEffect(() => {
    getStatisticsCount({
      startDate,
      endDate,
      applicationId,
      locationId,
      chargePointId,
      user,
      ocppTagId,
    }).then(
      (
        response?:
          | {
              data:
                | {
                    transactions: number;
                    durationInHrs: number;
                    evseSuspendedInHrs: number;
                    powerConsumptionInKWh: number;
                    endorsements: [
                      {
                        currency: string;
                        earnings: number;
                        cpoMargin: number;
                        dealerMargin: number;
                        revenue: number;
                      }
                    ];
                    drivers: number;
                    newUsers: number;
                    chargeStations: number;
                    acChargeStations: number;
                    dcChargeStations: number;
                  }
                | undefined;
            }
          | undefined
      ) => {
        setState(response?.data);
      }
    );
  }, [
    startDate,
    endDate,
    applicationId,
    locationId,
    chargePointId,
    user,
    ocppTagId,
  ]);
  return (
    <Row className='d-flex flex-wrap p-0'>
      <Col xl={12} lg={12} sm={12} xs={12}>
        <Row>
          <Col>
            <CardWidget
              label={t('Home.Transactions')}
              badge='ri-arrow-up-circle-line text-success'
              icon={getSocketSvg('TransactionsIcon', 'inherit')}
              iconv2={
                <Icon.Info
                  strokeWidth={2.5}
                  className='text-primary'
                  style={{ width: 16, fontWeight: 700, marginTop: -10 }}
                />
              }
              popoverdes={t('Home.popoverDes')}
              counter={state?.transactions || 0}
              url='#'
            />
          </Col>
          <Col>
            <CardWidget
              label={t('Home.powerConsumptionInKWh')}
              badge='ri-arrow-up-circle-line text-success'
              icon={getSocketSvg('EnergyIcon', 'inherit')}
              counter={state?.powerConsumptionInKWh || 0}
              suffix=' kWh'
              decimals={2}
              formattingFn={(val) => {
                return `${numeral(val).format('0.0,').toString()} kWh`;
              }}
              url='#'
            />
          </Col>
          <Col>
            <CardWidget
              label={t('Home.duration')}
              badge='ri-arrow-up-circle-line text-success'
              icon={getSocketSvg('Duration', 'inherit')}
              counter={state?.durationInHrs || 0}
              url='#'
            />
          </Col>
          <Col>
            <CardWidget
              label={t('Home.revenue')}
              badge='ri-arrow-up-circle-line text-success'
              icon={getSocketSvg('RevenueIcon', 'inherit')}
              counter={state?.endorsements[0]?.revenue}
              decimals={2}
              formattingFn={(val) =>
                `${numeral(val).format('0.0,').toString()} ${getLabelCurrency(
                  state?.endorsements[0]?.currency || 'TRY',
                  'symbol'
                )}`
              }
              url='#'
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default StatsCards;
