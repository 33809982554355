import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button } from 'reactstrap';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import EditContext from 'Components/Contexts/EditContext';
import { useTranslation } from 'react-i18next';
import Container from 'Components/Common/Container';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Plus } from 'react-feather';
import ExportBlock from 'laxyExcel/ExportComponents';
import { toast } from 'react-toastify';
import { deleteRole } from 'actions';
import * as Icons from 'react-feather';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import { RoleType } from '../../declerations/DefaultTypes';
import AddPermissions from './AddPermissions';
import EditPermission from './EditPermission';

const Permissions = () => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const editContext = useContext(EditContext);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const MySwal = withReactContent(Swal);
  const [isOpenEdit, setisOpenEdit] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  const loadDataTable = () => {
    context.LoadRoles().then(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    loadDataTable();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);
    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh]);

  // const headers = [
  //   'user.email',
  //   'user.fname',
  //   'user.lname',
  //   'role.title',
  //   'scopeType',
  // ].map((key) => ({
  //   key,
  //   value: `permissions.${key}`,
  // }));
  return (
    <Container
      title={t('Permissions')}
      rightSide={
        <AutoRefresh
          isActive={isActiveAutoRefresh}
          onPress={() => {
            setIsActiveAutoRefresh(!isActiveAutoRefresh);
          }}
        />
      }
    >
      <Row className='mb-2 d-flex justify-content-end'>
        <Col xl={3} sm={12} className=' d-flex justify-content-end'>
          {/* <ExportBlock
            data={context.permissions}
            header={headers}
            name={t('Roles')}
          /> */}
          <Button
            className='ms-1'
            aria-hidden='true'
            color='primary'
            onClick={() => {
              setAddIsOpen(!addIsOpen);
            }}
          >
            <Plus className='me-2 p-0' size='20' />

            {t('Add')}
          </Button>
        </Col>
      </Row>
      <div>
        <DataTable
          loading={loading}
          noDataText={t('Permission.thereArenoRoleCreated')}
          columns={[
            {
              name: t('Permission.title'),
              selector: (row: RoleType) => row.title,
            },
            {
              name: t('Permission.role'),
              selector: (row: RoleType) => row.role,
            },
            {
              name: t('Permission.scope'),
              selector: (row: RoleType) => row.scope,
            },
            {
              name: t('Permission.actions'),
              cell: (row: RoleType) => {
                return (
                  <div>
                    {row.deletable && (
                      <Icons.Edit3
                        aria-disabled
                        className='me-2'
                        cursor='pointer'
                        color='#7d8898'
                        onClick={() => {
                          editContext.SetEditRole(row);
                          setisOpenEdit(true);
                        }}
                      />
                    )}
                    {row.deletable && (
                      <Icons.Trash2
                        color={row.deletable ? '#f00' : '#999'}
                        className='mr-1'
                        cursor='pointer'
                        onClick={(e) => {
                          if (!row.deletable) return;
                          MySwal.fire({
                            title: t(
                              'Permission.doyouwanttoDeleteThisPermission'
                            ),
                            showConfirmButton: true,
                            showCancelButton: true,
                            cancelButtonText: t('no'),
                            confirmButtonText: t('yes'),
                            // eslint-disable-next-line consistent-return
                          }).then((result) => {
                            if (result.isConfirmed) {
                              return deleteRole(row?._id).then((res: any) => {
                                if (res.isSuccess === true) {
                                  loadDataTable();
                                  toast.success(
                                    t('Permission.deleteProcessSuccessful')
                                  );
                                } else {
                                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                  // eslint-disable-next-line no-lonely-if
                                  if (
                                    res.isSuccess === false &&
                                    res.message === 'CPBUSY'
                                  ) {
                                    toast.error(
                                      t('serverErrorPleaseContactSupport')
                                    );
                                  } else {
                                    toast.error(
                                      t('ChargeStations.deleteProcessFail')
                                    );
                                  }
                                }
                              });
                            }
                          });
                        }}
                      />
                    )}
                  </div>
                );
              },
            },
          ]}
          data={context.roles || []}
        />
      </div>
      {addIsOpen && (
        <AddPermissions
          isOpen={addIsOpen}
          toggleHandle={() => {
            loadDataTable();
            setAddIsOpen(false);
          }}
        />
      )}
      {isOpenEdit && (
        <EditPermission
          isOpen={isOpenEdit}
          toggleHandle={() => {
            loadDataTable();
            setisOpenEdit(false);
          }}
        />
      )}
    </Container>
  );
};
export default Permissions;
