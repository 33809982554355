import React, { useRef, useEffect } from 'react';

const useRefMemo = (effect: (a?: any) => any, dependencies: any[]) => {
  const prevDeps = useRef<any>();
  const result = useRef<any>();

  if (
    !prevDeps.current ||
    prevDeps.current.reduce(
      (acc: any, d: any, i: any) => acc || d !== dependencies[i],
      false
    )
  ) {
    prevDeps.current = dependencies;
    result.current = effect(result.current);
  }

  return result.current;
};

export default useRefMemo;
