/* eslint-disable react/require-default-props */
import React from 'react';

interface Props {
  children: React.ReactNode;
  color: string;
  className?: string;
  textColor?: string;
}

const TransactionStatusBadge = (props: Props) => {
  const { color, className, textColor, children } = props;
  return (
    <div
      className={
        className
          ? `${className} transactionStatusBadge`
          : 'transactionStatusBadge'
      }
      style={{
        color: textColor || '#fff',
        backgroundColor: color,
      }}
    >
      {children}
    </div>
  );
};

export default TransactionStatusBadge;
