import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import {
  getDashboard,
  getRevenueReport,
  getRevenue,
  getBestPerforming,
} from '../../actions/chargeStations';
import EnergyUsage from './Components/EnergyUsage';
import ConnectorRadialChart from './Components/ConnectorRadialChart';
import RevenueReport from './Components/RevenueReports';
import StatsCards from './Components/StatsCards';
import Location from './Components/Location';
import BestPerformance from './Components/BestPerformance';

type BestPerformanceRankingDataArray = {
  title: string;
  hour?: number;
  chargePointId?: number;
  vehicleMake?: string;
  vehicleModel?: string;
  location?: string;
  powerConsumptionInKWh?: number;
  durationInMins?: number;
};

type BestPerformanceRankingData = {
  title: string;
  data: BestPerformanceRankingDataArray[];
};

const Home = () => {
  const [revenueReport, setRevenueReport] = useState({
    expenses: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    earnings: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    currency: 'TRY',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [connectors, setConnectors] = useState([0, 0, 0, 0, 0]);
  const [connector, setConnector] = useState<
    | {
        occupied: number;
        faulted: number;
        available: number;
        offline: number;
        reserved: number;
        total: number;
        totalAC: number;
        totalDC: number;
      }
    | undefined
  >();
  const [revenue, setRevenue] = useState({
    energyUsage: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [revenueYear, setRevenueYear] = useState('2024');
  const [energyRevenueYear, setEnergyRevenueYear] = useState('2024');

  const [bestPerformingRank, setBestPerformingRank] = useState<
    BestPerformanceRankingData[] | undefined
  >([
    { title: 'TIMELINES', data: [] },
    { title: 'CARS', data: [] },
    { title: 'CHARGE STATIONS', data: [] },
    { title: 'LOCATIONS', data: [] },
  ]);

  const baseDate = new Date();
  let startDate: Date | string = new Date(baseDate.getFullYear(), 0, 1);
  let endDate: Date | string = new Date(
    baseDate.getFullYear(),
    baseDate.getMonth(),
    baseDate.getDate() + 1
  );
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  startDate = startDate.toISOString();
  endDate = endDate.toISOString();

  function initBestPorforming(): void {
    getBestPerforming({ startDate, endDate, lang: i18n.language }).then(
      (res) => {
        const timelines = bestPerformingRank?.find(
          (prevState) => prevState.title === `TIMELINES`
        );

        if (timelines) {
          timelines.data = res.data.bestPerformingTimeline.concat(
            res.data.bestPerformingTimelineDayOfWeek
          );
        }

        const chargeStations = bestPerformingRank?.find(
          (prevState) => prevState.title === `CHARGE STATIONS`
        );
        if (chargeStations)
          chargeStations.data = res.data.bestPerformingChargeStation;

        const cars = bestPerformingRank?.find(
          (prevState) => prevState.title === `CARS`
        );
        if (cars) cars.data = res.data.bestPerformingCars;

        const locations = bestPerformingRank?.find(
          (prevState) => prevState.title === `LOCATIONS`
        );
        if (locations) locations.data = res.data.bestPerformingLocation;

        if (chargeStations && timelines && cars && locations)
          setBestPerformingRank([timelines, cars, chargeStations, locations]);
      }
    );
  }

  useEffect(() => {
    getRevenueReport({ year: revenueYear })
      .then((response) => {
        setRevenueReport(response?.data);
      })
      .then(() => {
        getRevenue({ year: energyRevenueYear }).then((response) => {
          setRevenue(response?.data);
        });
      })
      .then(() => {
        getDashboard()
          .then((response) => {
            if (response && response.data) {
              const connectorData = response.data.connectors;
              setConnector(connectorData);
              setConnectors([
                connectorData.available,
                connectorData.occupied,
                connectorData.reserved,
                connectorData.faulted,
                connectorData.offline,
              ]);
            }
          })
          .then(() => {
            setIsLoading(!isLoading);
          });
      });
    initBestPorforming();
    i18n.on('languageChanged', () => {
      initBestPorforming();
    });
  }, []);

  const changeYear = (year: string) => {
    setRevenueYear(year);
    getRevenueReport({ year }).then((response) => {
      setRevenueReport(response?.data);
    });
  };

  const changeEnergyYear = (year: string) => {
    setEnergyRevenueYear(year);
    getRevenue({ year }).then((response) => {
      setRevenue(response?.data);
    });
  };
  return (
    <div className='page-content'>
      <Container fluid>
        <StatsCards />
        <RevenueReport revenueReport={revenueReport} changeYear={changeYear} />
        <Row>
          {bestPerformingRank?.map((el, ind) =>
            el.title !== 'LOCATIONS' ? (
              <Col key={ind} sm={12} md={12} lg={12} xl={4}>
                <BestPerformance title={el.title} data={el.data} />
              </Col>
            ) : null
          )}
        </Row>
        <Row>
          {bestPerformingRank?.map((el, ind) =>
            el.title === 'LOCATIONS' ? (
              <Col key={ind} sm={12} md={12} lg={12} xl={6}>
                <BestPerformance title={el.title} data={el.data} />
              </Col>
            ) : null
          )}
          <Col sm={12} md={12} lg={12} xl={6}>
            {isLoading && (
              <ConnectorRadialChart
                connectors={connectors}
                connector={connector}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Col sm={12} md={12} lg={12} xl={12}>
              <EnergyUsage
                revenue={revenue}
                changeEnergyYear={changeEnergyYear}
              />
            </Col>
          </Col>
        </Row>
        <Row>
          <Col>
            <Location />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
