/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getProfile } from 'actions';
import MenuItemContext from 'Components/Contexts/MenuItemContext';

// Import Icons
import { Icon as FeatherIcon } from 'ts-react-feather-icons';
import i18n from '../../i18n';
import hasGrant from '../../helpers/utils';

import DataContext from 'Components/Contexts/DataContext';

const Navdata = () => {
  const history = useHistory();
  // state data
  const context = useContext(DataContext);

  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isCpo, setIsCpo] = useState(false);
  const [isCpms, setIsCpms] = useState(false);
  const [isCrm, setIsCrm] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  const [filteredItem, setFilteredItem] = useState<any>({});

  const [iscurrentState, setIscurrentState] = useState('Dashboard');
  const [loading, setLoading] = useState(false);

  // function updateIconSidebar(e: any) {
  //   if (e && e.target && e.target.getAttribute('subitems')) {
  //     const ul = document.getElementById('two-column-menu');
  //     const iconItems = ul?.querySelectorAll('.nav-icon.active');
  //     const activeIconItems = iconItems;
  //     console.log('activeIconItems--', activeIconItems);
  //     console.log('activeIconItems--', activeIconItems);
  //     console.log('activeIconItems--', activeIconItems);
  //     console.log('activeIconItems--', activeIconItems);
  //     activeIconItems?.forEach((item) => {
  //       item.classList.remove('active');
  //       const id = item.getAttribute('subitems');
  //       if (id && document.getElementById(id))
  //         document.getElementById(id)?.classList.remove('show');
  //     });
  //   }
  // }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel');
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false);
    }
    if (iscurrentState !== 'Cpo') {
      setIsCpo(false);
    }
    if (iscurrentState !== 'Cpms' && iscurrentState !== 'Crm') {
      setIsCpms(false);
    }
    if (iscurrentState !== 'Crm') {
      setIsCrm(false);
    }
    if (iscurrentState !== 'Apps') {
      setIsApps(false);
    }
    if (iscurrentState !== 'Auth') {
      setIsAuth(false);
    }
    if (iscurrentState !== 'Pages') {
      setIsPages(false);
    }
    if (iscurrentState !== 'BaseUi') {
      setIsBaseUi(false);
    }
    if (iscurrentState !== 'AdvanceUi') {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== 'Forms') {
      setIsForms(false);
    }
    if (iscurrentState !== 'Tables') {
      setIsTables(false);
    }
    if (iscurrentState !== 'Charts') {
      setIsCharts(false);
    }
    if (iscurrentState !== 'Icons') {
      setIsIcons(false);
    }
    if (iscurrentState !== 'Maps') {
      setIsMaps(false);
    }
    if (iscurrentState !== 'MuliLevel') {
      setIsMultiLevel(false);
    }
    if (iscurrentState === 'Widgets') {
      history.push('/widgets');
      document.body.classList.add('twocolumn-panel');
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
    isCpo,
    isCpms,
    isCrm,
  ]);
  const dataMapper = (menuItems: any) => {
    return menuItems;
  };
  useEffect(() => {
    const populateArray = async () => {
      context.LoadProfile().then(async (profile: any) => {
        const hasReports = hasGrant(profile?.defaultGrants, 'reports');
        const hasEmra = hasGrant(profile?.defaultGrants, 'emra');
        const hasTaskConfig = hasGrant(profile?.defaultGrants, 'taskconfigs');
        const hasTasks = hasGrant(profile?.defaultGrants, 'tasks');

        const menuItems = [
          {
            label: 'Menu',
            isHeader: true,
          },
          {
            id: 'overview',
            label: 'Overview',
            icon: <FeatherIcon name='home' />,
            link: '/overview',
          },
          {
            id: 'cpo',
            label: 'CPO',
            type: 'item',
            icon: <FeatherIcon name='battery-charging' />,
            // permissions: ['admin', 'person'],
            // link: '/cpo/charge-station',
            // stateVariables: true,
            // click: function (e: any) {
            //   e.preventDefault();
            //   setIsCpo(!isCpo);
            //   setIscurrentState('Cpo');
            //   updateIconSidebar(e);
            // },
            subItems: [
              {
                id: 'chargestation',
                label: 'Charge Stations',
                link: '/cpo/charge-station',
                parentId: 'Cpms',
              },
              {
                id: 'CharginRate',
                label: 'Tariffs.tariffs',
                link: '/cpo/tariffs',
              },
              {
                id: 'alarms',
                label: 'Alarms',
                type: 'item',
                link: '/cpo/alarms',
              },
              {
                id: 'charging-site',
                label: 'Charging Site',
                parentId: 'Cpms',
                link: '/cpo/charging-site',
              },
              {
                id: 'authorization',
                label: 'Authorization',
                parentId: 'Cpms',
                link: '/cpo/authorization',
              },
              {
                id: 'transaction',
                label: 'Transactions',
                type: 'item',
                link: '/cpo/transaction',
              },
              {
                id: 'report',
                label: 'Report',
                type: 'item',
                link: '/cpo/report',
                isHidden: !hasReports,
              },
            ],
          },
          {
            id: 'cpms',
            label: 'E-MSP',
            type: 'item',
            icon: <FeatherIcon name='list' />,
            // permissions: ['admin', 'person'],
            // link: '/cpos',
            // stateVariables: true,
            // isChildItem: true,

            subItems: [
              {
                id: 'emra',
                label: 'Emra EPDK Config',
                link: '/emra',
                parentId: 'Cpms',
                isHidden: !hasEmra,
              },
              {
                id: 'integrationtaskconfigs',
                label: 'Integration Task Config',
                link: '/integrationtaskconfigs',
                parentId: 'Cpms',
                isHidden: !hasTaskConfig,
              },
              {
                id: 'integrationtasks',
                label: 'Integration Tasks',
                link: '/integrationtasks',
                parentId: 'Cpms',
                isHidden: !hasTasks,
              },
              {
                id: 'cpos',
                label: 'Charge Point Operators',
                link: '/cpos',
                parentId: 'Cpms',
              },
              {
                id: 'report',
                label: 'Report',
                type: 'item',
                link: '/report',
                isHidden: !hasReports,
              },
              {
                id: 'payments',
                label: 'CDR',
                type: 'item',
                link: '/CDR',
              },
              // {
              //   id: 'session',
              //   label: 'session',
              //   link: '/session',
              // },
              {
                id: 'permission',
                label: 'Roles',
                type: 'item',
                link: '/roles',
              },
              {
                id: 'roles',
                label: 'Permissions',
                type: 'item',
                link: '/permissions',
              },

              {
                id: 'crm',
                label: 'CRM',
                // link: '/drivers',
                isChildItem: true,
                icon: <FeatherIcon name='battery-charging' />,
                parentId: 'cpms',
                // click: function (e: any) {
                //   e.preventDefault();
                //   setIsCrm(!isCrm);
                //   setIscurrentState('Crm');
                // },
                stateVariables: true,
                childItems: [
                  {
                    id: 'drivers',
                    label: 'Drivers',
                    link: '/drivers',
                  },
                  {
                    id: 'vehicles',
                    label: 'Vehicles',
                    link: '/vehicles',
                  },
                  {
                    id: 'employees',
                    label: 'Employees',
                    link: '/employees',
                  },
                ],
              },
            ],
          },
        ];
        const latest = await dataMapper(menuItems);
        setFilteredItem(latest);
        setLoading(true);
      });
    };

    populateArray();
    return () => {};
  }, []);

  return <>{loading && filteredItem}</>;
};
export default Navdata;
