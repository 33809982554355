import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
export const getEmspChargePoints = () => {
  const apiUrl = `emsp/chargepoints?limit=2000`;
  return api.get(apiUrl);
};
export const getEmspEmras = () => {
  const apiUrl = `emras?type=EPDK`;
  return api.get(apiUrl);
};
export const editEmspChargePoints = (params: object) => {
  const apiUrl = `/emras`;
  return api.create(apiUrl, params);
};
