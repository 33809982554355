import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
export const getChargingRates = (filter?: object) => {
  const queryParams = { limit: 500, ...filter };
  const apiUrl = `charging-rates/`;
  return api.get(apiUrl, queryParams);
};

export const createChargingRates = (params?: object) => {
  const apiUrl = `charging-rates/`;
  return api.create(apiUrl, params);
};

export const editChargingRates = (id: string, params: object) => {
  const apiUrl = `charging-rates/${id}`;
  return api.update(apiUrl, params);
};


export const getChargingRateHistories = (filter?: object) => {
  const apiUrl = `charging-rates/history`;
  return api.get(apiUrl, filter);
};
