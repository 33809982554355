const BASE_URL = process.env.REACT_APP_BASE_URL;
const URL_PATH = process.env.REACT_APP_URL_PATH;
const SERVER_URL = `${BASE_URL}${URL_PATH}`;
const GENERAL_SERVER_URL = `${BASE_URL}${URL_PATH?.split('/')
  .slice(0, -2)
  .join('/')}/`;
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
let [tenant, env] = window.location.hostname.split('.');
if (!['uat', 'dev'].includes(env)) {
  env = 'prod';
}
// Uncomment below line while developing
// tenant = 'devtest';

// tenant = 'chargee';

/* eslint prefer-const: "off" */
const OCPP_CPMS_WS = `wss://${tenant}.${process.env.REACT_APP_OCPP_CPMS_WS}`;

const OCPP_REST_API_URL = `https://${tenant}.${process.env.REACT_APP_OCPP_REST}api`;

const TENANT_ENV_URL = `https://${tenant}${env === 'prod' ? '' : `.${env}`}`;

const DOMAIN_KEY = tenant;

// const ASSET_BASE_URL = `https://${process.env.REACT_APP_ASSETS_BASE}${env}/${tenant}/`;
const ASSET_BASE_URL = `https://${process.env.REACT_APP_ASSETS_BASE}prod/${tenant}/`;

const ASSETS_URL = `${process.env.REACT_APP_ASSETS_URL}`;

const TENANT_TITLE = `${tenant.substr(0, 1).toUpperCase()}${tenant
  .substr(1)
  .toLowerCase()}`;

const THEME_CONFIG_URL = `${ASSET_BASE_URL}themeConfig.jsonp?${Math.floor(
  Math.random() * 1000000
)}`;

const AUTO_REFRESH_TIME = 60 * 1000;

const DISABLED_TENANTS = ['e-power'];

const IS_TENANT_DISABLED = DISABLED_TENANTS.includes(tenant)

const TENANTS_V2 = [
  'devtest',
  'staging',
  'nevasarj',
  'b-charge',
  'epsis',
  'otojet',
  'e-power',
  'armatec',
  'vale',
  '360enerji',
  'meis',
  'moobiapp',
  'lumicle',
];

export {
  ASSET_BASE_URL,
  ASSETS_URL,
  BASE_URL,
  OCPP_CPMS_WS,
  OCPP_REST_API_URL,
  URL_PATH,
  SERVER_URL,
  GOOGLE_MAP_API_KEY,
  TENANT_TITLE,
  THEME_CONFIG_URL,
  DOMAIN_KEY,
  GENERAL_SERVER_URL,
  AUTO_REFRESH_TIME,
  TENANT_ENV_URL,
  TENANTS_V2,
  IS_TENANT_DISABLED,
  DISABLED_TENANTS,
};
