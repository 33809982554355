import mime from 'mime-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
const axiosInstance = axios.create();
export const getLocations = (filter?: object) => {
  const apiUrl = `locations/`;

  return api.get(apiUrl, filter);
};
export const getLocationById = (id: string, params: object) => {
  const apiUrl = `locations/${id}`;
  return api.get(apiUrl, params);
};
export const createLocation = (params: object) => {
  const apiUrl = `locations`;
  return api.create(apiUrl, params);
};
export const editLocation = (id: string, params: object) => {
  const apiUrl = `locations/${id}`;
  return api.update(apiUrl, params);
};

export const deleteLocationPhoto = async (id: string, params: object) => {
  const apiUrl = `locations/${id}/images/`;
  return api.update(apiUrl, params);
};

export const uploadLocationPhoto = async (
  id: string,
  file: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: any
) => {
  const apiUrl = `locations/${id}/images/`;
  const fileType = file?.type;
  const extension = mime.extension(fileType);
  const data: {
    isSuccess?: boolean;
    data: {
      locImagePostObj: { fields: { key: string }; url: string };
      locImageUrl?: string;
      imageUrl?: never;
    };
  } = await api.create(apiUrl, {
    locImage: {
      extension,
    },
  });
  if (!data) {
    return data;
  }
  const { locImagePostObj, locImageUrl } = data.data;
  const { fields, url } = locImagePostObj;
  const formData = new FormData();
  Object.entries(fields).forEach(([key, value]) => {
    // if (typeof value === 'string' || typeof value === typeof Blob)
    formData.set(key, value);
  });
  formData.set('file', file);
  const toastId: React.ReactText = '';
  try {
    const response = await axiosInstance.request({
      method: 'POST',
      url,
      data: formData,
      onUploadProgress: (p: { loaded: number; total: number }) => {
        const progress = p.loaded / p.total;
        // check if we already displayed a toast
        if (toastId) {
          toast.update(toastId, {
            progress,
          });
        }
      },
    });
    return {
      isSuccess: true,
      data: { locImagePostObjUrl: `/${data.data.locImagePostObj.fields?.key}` },
    };
  } catch (e) {
    toast.error(t('ChargeStationActions.failedToUploadPhoto'));
    return { isSuccess: false };
  } finally {
    if (toastId) {
      toast.dismiss(toastId);
    }
  }
};
